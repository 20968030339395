import React, { useState, useEffect } from 'react'
import "./Club.css";
import Save from './Form/Save';
import Path from '../Path/Path';
import Share from '../Share/Share';
import Folder from '../Folder/Folder';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Sessions from '../Sessions/Sessions';
import { INFO } from '../../utils/constants';
import { resetFilter } from '../../actions/filter'
import { resetParam } from '../../actions/sortParam'
import { resetToggle } from "../../actions/toggleView"
import { setActiveSidebar } from '../../actions/sidebar';
import { SessionContext } from '../Context/SessionContext';
import { isPublic, isPrivate } from "../../actions/isPublic"

export default function Clubs({ clubCoach, reset, showClubForm, setshowClubForm, setinClub, setinShared, setshowMember }) {
    const dispatch = useDispatch();
    const [show, setShow] = useState(0);
    const [path, setpath] = useState([]);
    const [folderId, setfolderId] = useState("");
    const club = useSelector((state) => state.club);
    const [folderName, setfolderName] = useState("");
    const [showShare, setShowShare] = useState(false);
    const pId = useSelector(state => state.folder.pId)
    const [sharedUserID, setsharedUserID] = useState("");
    const folders = useSelector((state) => state.folderList);
    const [folderLoading, setfolderLoading] = useState(true);
    const toggleView = useSelector(state => state.toggleView);
    const coachName = useSelector(state => state.coachId?.username);
    const [showEdit, setShowEdit] = useState({ id: 0, status: false })
    const [selectedmember, setselectedmember] = useState(setshowMember);
    const [showDropdown, setShowDropdown] = useState({ id: 0, status: false })
    const [sessionLoading, setsessionLoading] = useState({ loading: true, length: 0 });
    
    useEffect(() => {
        if (!clubCoach) {
            dispatch(setActiveSidebar(3))
            dispatch(isPublic(true))
        }
        window.scrollTo({ top: 0, left: 0 });
        setinShared(false)
        setinClub(true)

        return () => {
            dispatch(resetFilter())
            dispatch(resetToggle())
            dispatch(resetParam())
            setinClub(false)

        }
    }, [])

    useEffect(()=>{
        setShow(0)
    },[toggleView])

    const handlePath = (p, limit) => {
        if (limit === "clear") {
            setpath([])
        } else {
            let count = 0
            let newPath = path.filter((inner) => {
                if (count !== limit) {
                    count += 1
                    return inner
                }
            })
            setpath(newPath)
        }
    }


    const handleDropdownClick = (e, data) => {
        e.stopPropagation();
        if (data === INFO.MOVE_TO_FOLDER) {
            setShow(2);
        } else if (data === INFO.SHARE_SESSION) {
            setShowShare({ id: showDropdown.id, status: true })
            setShow(0)
        } else if (data === INFO.DOWNLOAD) {
            setShow(3)
        } else if (data === INFO.MAKE_A_COPY) {
            setShow(4)
        } else if (data === INFO.EDIT) {
            setShowEdit({ id: showDropdown.id, status: true })
            setShow(0)
        } else if (data === INFO.MOVE_TO_TRASH) {
            setShow(5)
        }
    }

    const handleClick = () => {
        setShowDropdown({ id: 0, status: false })
        setShowEdit({ id: 0, status: false });
        setShowShare({ id: 0, status: false });
        setShow(0)
    }

    return (
        <SessionContext.Provider value={{
            // getSessions,
            show, setShow,
            showEdit, setShowEdit,
            showShare, setShowShare,
            showDropdown, setShowDropdown,
            handleClick, handleDropdownClick,
        }}>
            <div className="folder-path-parent" onClick={()=>{handleClick()}}>
                <Path reset={reset} handlePath={handlePath} path={path} title={coachName || "Club"} />
                {/* 
                    if new to club and admin show the club creation form
                */}

                {/* folders */}
                {toggleView && <Folder setshowMember={selectedmember} folderLoading={folderLoading} setfolderLoading={setfolderLoading} sessionLoading={sessionLoading} clubCoach={clubCoach} setfolderName={setfolderName} setShowShare={setShowShare} setfolderId={setfolderId} setpath={setpath} setsharedUserID={setsharedUserID} />}
                {(pId !== undefined || !toggleView) && <Sessions setshowMember={selectedmember} sessionLoading={sessionLoading} setsessionLoading={setsessionLoading} clubCoach={clubCoach} inClub={true} pId1={pId} />}
                {(showClubForm) && <Save setshowClubForm={setshowClubForm} />}
                {showShare?.status && <Share setshowMember={selectedmember} folderName={folderName} setShowShare={setShowShare} folderId={folderId} sharedUserID={sharedUserID} />}
            </div >
        </SessionContext.Provider >
    )
}                   