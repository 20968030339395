import React, { useState, useEffect } from 'react'
import { saveFolder, saveSubClub } from '../../apis'
import { useSelector, useDispatch } from 'react-redux';
import { addFolder } from '../../actions/folderList';
import { setParent } from '../../actions/folder';
import { FolderImg } from "../../assets/constants/img.js";
import { Modal, Button, Image } from "react-bootstrap";

export default function SubClubs({ folderParent, setFolderList, clubDetails, setshowSubClub, parentFolderId, setfolders, isPublicOverride }) {
  const clubId = useSelector((state) => state.club.details);
  const pId = useSelector((state) => state.folder.pId);
  const isPublic = useSelector(state => state.isPublic)
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.user.id);
  const [folderName, setfolderName] = useState("Untitled Folder")
  const [show, setShow] = useState(true);
  const [error, seterror] = useState(false)
  const [stop, setstop] = useState(0)
  const handleClose = () => { setShow(false); setshowSubClub(false) };

  const handleSave = async () => {
    try {
      if (folderName.trim() !== "") {
        setstop(1)
        let { data } = clubId.id == 1 ? await saveFolder({ folderName, parentId: folderParent || pId, userId, isPublic: isPublicOverride === undefined ? isPublic : isPublicOverride }) : await saveSubClub({ folderName, clubId: clubId.id, parentId: folderParent || pId, userId, isPublic: isPublicOverride === undefined ? isPublic : isPublicOverride})
        dispatch(addFolder({ id: data.subClubId, name: folderName, club_id: clubId.id, parent_id: pId, user_id: userId}))
        setfolders && setfolders(folders => {
          return [{ id: data.subClubId, name: folderName, club_id: clubId.id, parentId: pId }].concat(...folders)
        })

        setFolderList && setFolderList(folders => {
          return [].concat(...folders, [{ id: data.subClubId, name: folderName, club_id: clubId.id, parentId: pId }])
          // return [{ id: data.subClubId, name: folderName, club_id: clubId.id, parentId: isPublicOverride }].concat(...folders)
        })
        setstop(0)
        setshowSubClub(false)
        setShow(false)
      } else { }
      seterror(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} className="folder-modal">
      <Modal.Header closeButton>
        <Modal.Title>Create folder <Image src={FolderImg} /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {clubId?.id ? <input type="text" placeholder="Folder name" className="folder-input" value={folderName} onChange={(e) => { setfolderName(e.target.value); seterror(false) }} /> : <p>No Club Created</p>}
        {error && <p className="error">Enter the Folder name</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {clubId?.id && <Button variant="primary" onClick={() => { stop === 0 && handleSave() }}>
          Save
        </Button>}
      </Modal.Footer>
    </Modal>
  )
}
