import React, { useState } from 'react'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Modal, Button } from "react-bootstrap";
import { editSession } from '../../actions/sessionList';
import { setToast } from '../../actions/toast'
import { useDispatch } from 'react-redux';
import { ageOptions, playerOptions, tactics, playPhase, practiceType, gameFormat } from "../../constants"


export default function Edit({ data, showEdit, setshowEdit }) {
    const dispatch = useDispatch()
    const [error, seterror] = useState(false)
    const [formData, setformData] = useState({ session_name: data?.session_name, age_group: data?.age_group, number_of_players: data?.number_of_players, id: data?.id, tactics: data?.tactics, phase_of_play: data?.phase_of_play, practice_type: data?.practice_type, game_format: data?.game_format })
    const editSessionHandler = () => {
        if (formData.session_name.trim() !== "") {
            dispatch(editSession({ sessionId: data.id, formData }))
            setshowEdit(false)
            dispatch(setToast("Session updated Successfully!"))
        } else {
            seterror(true)
        }
    }

    const handleClose = () => {
        setshowEdit(false)
    }
    return (
        <Modal show={showEdit} onHide={handleClose} className="folder-modal club-description-form edit-popup">
            <Modal.Header closeButton>
                <Modal.Title> Edit Session Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"club-desc-row" + (error ? " required-error" : "")}>
                    <label>Session Title: </label>
                    <input type="text" placeholder="Enter session title" value={formData.session_name} onChange={(e) => { setformData({ ...formData, session_name: e.target.value }); seterror(false) }} />
                    {error && <p className="error">Session title is required</p>}
                </div>
                <div className="club-desc-row"><label>Age-Group: </label>
                    <Dropdown options={ageOptions} value={formData.age_group} placeholder="Select age group" onChange={(e) => { setformData({ ...formData, age_group: e.value }); }} />
                </div>
                <div className="club-desc-row"><label>Number of Players: </label>
                    <Dropdown options={playerOptions} value={formData.number_of_players} onChange={(e) => { setformData({ ...formData, number_of_players: e.value }); }} />
                </div>
                <div className="club-desc-row"><label>Tactics </label>
                    <Dropdown options={tactics} value={formData.tactics} onChange={(e) => { setformData({ ...formData, tactics: e.value }); }} />
                </div>
                <div className="club-desc-row"><label>Phase of PLay: </label>
                    <Dropdown options={playPhase} value={formData.phase_of_play} onChange={(e) => { setformData({ ...formData, phase_of_play: e.value }); }} />
                </div>
                <div className="club-desc-row"><label>Practice Type: </label>
                    <Dropdown options={practiceType} value={formData.practice_type} onChange={(e) => { setformData({ ...formData, practice_type: e.value }); }} />
                </div>
                <div className="club-desc-row"><label>Game Format: </label>
                    <Dropdown options={gameFormat} value={formData.game_format} onChange={(e) => { setformData({ ...formData, game_format: e.value }); }} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={editSessionHandler}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
