import { SET_TOGGLE, RESET_TOGGLE} from '../constants';

export const setToggle = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_TOGGLE, payload: value});
  } catch (error) {
    console.log(error);
  }
};


export const resetToggle = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_TOGGLE });
  } catch (error) {
    console.log(error);
  }
};
