import { UPDATE_SESSION_LIST, CLEAR_SESSION, SESSION_LIST, UPDATE_SHARED_USER, UPDATE_SESSION ,COPY_SESSION} from '../constants';
import * as api from '../apis/index.js';

export const sessionList = (sessions) => async (dispatch) => {
  try {
    dispatch({ type: SESSION_LIST, payload: sessions });
  } catch (error) {
    console.log(error);
  }
};

export const deleteSession = (sessionId) => async (dispatch) => {
  try {
    // await api.deleteSession(sessionId);
    dispatch({ type: UPDATE_SESSION_LIST, payload: sessionId });
  } catch (error) {
    console.log(error);
  }
};

export const clearSessionList = () => async (dispatch) => {
  try {
    // const { data } = await api.saveSubClub(sessionData);
    dispatch({ type: CLEAR_SESSION, payload: [] });
  } catch (error) {
    console.log(error);
  }
};

export const changeAccess = (data) => async (dispatch) => {
   console.log(data, "console change access data====");
  try {
    await api.changeAccess(data);
    dispatch({ type: UPDATE_SESSION_LIST, payload: data.id });
  } catch (error) {
    console.log(error);
  }
};

export const updateSharedUserID = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SHARED_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const editSession = (data) => async (dispatch) => {
  try {
    await api.editSession(data);
    dispatch({ type: UPDATE_SESSION, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const copySession = (data) => async (dispatch) => {
  try {
    // await api.copySession(data);
    dispatch({ type: COPY_SESSION, payload: data });
  } catch (error) {
    console.log(error);
  }
};