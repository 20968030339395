import { SET_TOGGLE, RESET_TOGGLE} from '../constants';


export default (toggle = true, action) => {
    switch (action.type) {
      case SET_TOGGLE:
        return action.payload
      case RESET_TOGGLE:
        return true;
      default:
        return toggle;
    }
  };
  