import { CLUB_DETAIL, CLUB_MEMBERS, UPDATE_CLUB_DESCRIPTION  } from '../constants';
import * as api from '../apis/index.js';

export const getClubDetails = (userId) => async (dispatch,state) => {
  // console.log('club data is === ',state().club);
  try {
    const { data } = await api.clubDetails({userId,admin:state().user.isAdmin });
    await dispatch({ type: CLUB_DETAIL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setClubDetails = (data) => async (dispatch) => {
    try {
    // const { data } = await api.clubDetails(userId);
    
    dispatch({ type: CLUB_DETAIL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getMembers = (clubId) => async (dispatch) => {
  try {
    const { data } = await api.getMembers(clubId);
    dispatch({ type: CLUB_MEMBERS, payload: data.members });
  } catch (error) {
    console.log(error);
  }
};

export const updateClubDescription = (formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CLUB_DESCRIPTION, payload: formData });
  } catch (error) {
    console.log(error);
  }
};