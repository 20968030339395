import React, { useContext, useState } from 'react'
import { saveAs } from 'file-saver'
import { SessionContext } from '../Context/SessionContext'
import { DOWNLAOD_PNG, PDF_FILE } from "../../assets/constants/img.js";

export const Download = ({ data }) => {
    const [isShow, setIsSHow] = useState(false);
    const [isSelect, setIsSelect] = useState(0);
    const [isDisable, setIsDisable] = useState(true);
    const sessionName = data.session_name.replace(/\s/g, "");
    const pdfUrl = `http://legacy.touchtight.com/components/com_sessioncreatorv2/assets/images/session_pdf/${data.user_id}/session_${data.id}/${sessionName}.pdf`
    const {
        setShow,
        handleClick,
    } = useContext(SessionContext)

    const addClass = () => {
        setIsSHow(!isShow);
    }

    const handleSelect = (select) => {
        setIsSelect(select)
        addClass()
        if (select === 1) {
            setIsDisable(!(data.thumbnail_path !== null))
        }

        if (select === 2) {
            setIsDisable(!(data.generate_pdf === 1))
        }
    }

    const handleDownloadClick = () => {
        if (isSelect === 1) {
            saveAs(data.thumbnail_path, data.session_name + data.id + '.jpg')
        } else {
            window.open(pdfUrl, "_blank");
        }

        handleClick();
    }

    return (
        <div className="newDropdownLayout_Parent" id="downloadBox">
            <div className="header">
                <button className='back' onClick={() => { setShow(1) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M6.9 10.996h11.596a.75.75 0 0 1 0 1.5H6.9l4.377 4.377a.75.75 0 0 1-1.061 1.06l-4.95-4.95a1.75 1.75 0 0 1 0-2.474l4.95-4.95a.75.75 0 1 1 1.06 1.06L6.9 10.996z" fill="currentColor"></path></svg>
                </button>
                <h3>Download</h3>
            </div>
            <h5 className='download-subhead'>File Type</h5>
            <div id="selectBoxCustom" onClick={() => { addClass() }}>
                <div className="tooltip-parent footer-switch dropdown-item" >
                    {isSelect === 0 && <p className='defaultText'>Select type</p>}
                    {isSelect === 1 && <button className="img-span">
                        <img src={DOWNLAOD_PNG} />
                        <p>Download Image</p>
                    </button>}
                    {isSelect === 2 && <span className="pdf-span"><img src={PDF_FILE} /><p>Preview PDF</p></span>}

                </div>
                <div className='arrow'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path></svg>
                </div>
            </div>
            <div className={`downloadTypeList ${isShow ? "active" : ""}`}>
                <div className='downloadTypeListItems'>
                    <div className={`tooltip-parent footer-switch dropdown-item ${data.thumbnail_path !== null ? "" : "disable"}`} >
                        <span onClick={() => handleSelect(1)} className={`img-span `}><img src={DOWNLAOD_PNG} /><p>Download Image</p></span>
                    </div>
                    <div className={`tooltip-parent footer-switch dropdown-item ${data.generate_pdf === 1 ? "" : "disable"}`}>
                        <span className={`pdf-span `} onClick={() => handleSelect(2)}><img src={PDF_FILE} /><p>Preview PDF</p></span>
                    </div>
                </div>
            </div>
            <div className="downloadBtnBox">
                <button className="btn downloadBtn" disabled={isDisable} onClick={() => { handleDownloadClick() }}>{isSelect === 2 ? "Preview" : "Download"}</button>
            </div>
        </div>
    )
}
