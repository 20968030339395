import { UPDATE_SESSION_LIST, CLEAR_SESSION, SESSION_LIST, UPDATE_SHARED_USER, UPDATE_SESSION, COPY_SESSION } from '../constants';


export default (sessionList = [], action) => {
  switch (action.type) {
    case UPDATE_SESSION_LIST:
      return sessionList.filter(session => session.id != action.payload)
    case SESSION_LIST:
      console.log(action.payload,",============================ session list");
      return action.payload || []
    case CLEAR_SESSION:
      return []
    case UPDATE_SHARED_USER:
      return sessionList.map(session => {
        if (action.payload.sessionId === session.id)
          session.sharedUserID = action.payload.selected
        return session
      })
    case UPDATE_SESSION:
      return sessionList.map(session => {
        if (action.payload.sessionId === session.id) {
          var saveData = action.payload.formData;
          return { ...session, session_name: saveData.session_name, age_group: saveData.age_group, number_of_players: saveData.number_of_players, tactics: saveData.tactics, phase_of_play: saveData.phase_of_play, practice_type: saveData.practice_type, game_format: saveData.game_format }
        }
        return session
      })
    case COPY_SESSION:
      return [action.payload.sessiondata, ...sessionList];
    default:
      return sessionList;
  }
};
