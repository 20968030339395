import { ACTIVE_SIDEBAR } from '../constants';


export default (activeSidebar = 1, action) => {
    switch (action.type) {
        case ACTIVE_SIDEBAR:
            return action.payload;
        default:
            return activeSidebar;
    }
};
