import { SET_PARENT  } from '../constants';


export default (folder = {pId:undefined}, action) => {
    switch (action.type) {
      case SET_PARENT:
        folder.pId = action.payload
        return folder;
      // case CREATE:
      //   return [...posts, action.payload];
      // case UPDATE:
      //   return posts.map((post) => (post._id === action.payload._id ? action.payload : post));
      // case DELETE:
      //   return posts.filter((post) => post._id !== action.payload);
      default:
        return folder;
    }
  };
  