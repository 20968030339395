import React, { useState, useEffect } from 'react'
import { MdCreate } from "react-icons/md";
import SubClubs from '../SubClubs/SubClubsPopup';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setParent } from '../../actions/folder';
import { isPublic, isPrivate } from "../../actions/isPublic"
import useWindowResize from '../../custom-hooks/useWindowResize';
import { resetToggle } from '../../actions/toggleView';
// import Create from '../../Components/CreateClub/Create';
import './header.css';


export default function Header({clubCoach, myArea, setshowSideBar, showForm, setshowClubForm, clubDetails, parentFolderId, setfolders, inClub, inShared ,inDashboard}) {
    const [getPrivate, setgetPrivate] = useState(false)
    const [showSubClub, setshowSubClub] = useState(false)
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [matchWidth, setmatchWidth] = useState(window.matchMedia("(max-width: 1024px)").matches)
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); setshowSubClub(true) };
    const club = useSelector(state => state.club)
    const clubstate = useSelector(state => state)
    const toggleView = useSelector(state => state.toggleView)
    // useEffect(() => {
    //     setmatchWidth(window.matchMedia("(max-width: 1024px)").matches)
    // }, [window.innerWidth])
    useEffect(() => {
        setgetPrivate(false);
    },[inClub])
    useWindowResize(setmatchWidth)

    return (
        <div className="main-right-header custom-right-header">
            {matchWidth && <button onClick={() => { setshowSideBar(true) }} className="menu-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0671dd"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" /></svg>
            </button> }
            {(inClub || clubCoach) && <h3>{club?.details?.id === 1 ? clubCoach ? "Club" : "Add Club" : club?.details?.name}{clubCoach === false && <MdCreate onClick={() => setshowClubForm(true)} />}</h3>}
            {inShared && <h3>Shared Session</h3>}
            {(myArea) && <h3>My Area</h3>}
            {(!inShared && !inClub && !myArea && !clubCoach) && <h3>My Sessions</h3>}
            {(!inShared && (!inClub || inClub && clubCoach) && !(!inClub && clubCoach) && !myArea) && <div className="touch-tab">
                <button className={getPrivate === true ? "active" : ""} onClick={() => { if (getPrivate === false) { setgetPrivate(true); dispatch(isPrivate(true)); dispatch(setParent(undefined)) } }}>Private</button>
                <button className={getPrivate === false ? "active" : ""} onClick={() => { if (getPrivate === true) { setgetPrivate(false); dispatch(isPublic(true)); dispatch(setParent(undefined)) } }}>Public</button>
            </div>}
             <div className="rightfolder-header">
                {(inDashboard || myArea || clubCoach) && <div>
                    {!showForm &&
                        <>
                            {!inShared && toggleView ? <button className="add-folder" onClick={handleShow}>New Folder</button> : ""}
                            {showSubClub && <SubClubs parentFolderId={parentFolderId} clubDetails={clubDetails} setfolders={setfolders} setshowSubClub={setshowSubClub} />}
                        </>}
                </div>}
            </div>
        </div>
    )
}
