import { IS_PUBLIC, IS_PRIVATE  } from '../constants';


export default (isPublic = 1, action) => {
    switch (action.type) {
      case IS_PUBLIC:
        return 1;
      case IS_PRIVATE:
        return 0;
      default:
        return isPublic;
    }
  };
  