import * as api from '../apis';
import {IS_ADMIN, EDIT_USER_DETAILS} from '../constants'
// import jwt_decode from "jwt-decode";

export const getUserId = (token) => async (dispatch) => {
  try {
    const { data } = await api.verifyToken(token);
    console.log("data on verify token : ", data);
    // debugger;
    if(isNaN(data.userId?.id)) window.location.replace("http://legacy.touchtight.com/logout");
    await dispatch({ type: "userId", payload: data.userId  });
    localStorage.setItem("token", token)
  } catch (error) {
    console.log(error);
  }
};
// 14141

export const isAdmin = (userId) => async (dispatch) => {
  try {
    const { data } = await api.isAdmin(userId);
    await dispatch({ type: IS_ADMIN, payload: data  });
  } catch (error) {
    console.log(error);
  }
};


export const editDetails = (UserData) => async (dispatch) => {
  try {
    const { data } = await api.editDetails(UserData);
    dispatch({ type: EDIT_USER_DETAILS, payload: UserData  });
  } catch (error) {
    console.log(error);
  }
};