import { UPDATE_FOLDER_LIST, DELETE_FOLDER, ADD_FOLDER, CLEAR_FOLDER, UPDATE_SHARED_USER } from '../constants';
import * as api from '../apis/index.js';

export const folderList = (data) => async (dispatch) => {
  try {
    // const { data } = await api.saveSubClub(folderData);
    dispatch({ type: UPDATE_FOLDER_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};


export const addFolder = (data) => async (dispatch) => {
  try {
    // const { data } = await api.saveSubClub(folderData);
    dispatch({ type: ADD_FOLDER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const clearFolderList = () => async (dispatch) => {
  try {
    // const { data } = await api.saveSubClub(folderData);
    dispatch({ type: CLEAR_FOLDER, payload: [] });
  } catch (error) {
    console.log(error);
  }
};

export const deleteFolder = (id) => async (dispatch) => {
  try {
    await api.deleteFolder(id);

    dispatch({ type: DELETE_FOLDER, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const updateFolderSharedUserID = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SHARED_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};