import React, { useState, useEffect, useContext } from 'react'
import './ChangeAccess.css';
import SubClubs from '../SubClubs/SubClubs';
import { INFO } from '../../utils/constants';
import { setToast } from '../../actions/toast';
import { useSelector, useDispatch } from 'react-redux';
import { SessionContext } from '../Context/SessionContext';
import { changeAccess } from '../../actions/sessionList.js';
import { getFoldersToChangeAccess, normalUserGetFoldersToChangeAccess } from '../../apis';

export default function ChangeAccess({ data, handleApiCall }) {
    const dispatch = useDispatch();
    const [pId, setpId] = useState(undefined);
    const [folderId, setfolderId] = useState(0);
    const [toPublic, setToPublic] = useState(0);
    const [folderlist, setFolderList] = useState([]);
    const [addFolder, setaddFolder] = useState(false);
    const userId = useSelector(state => state.user?.id);
    const isPublic = useSelector(state => state.isPublic);
    const clubId = useSelector((state) => state.club.details);
    const coachId = useSelector((state) => state.coachId?.id);
    const toggleView = useSelector(state => state.toggleView);
    const [folderlistBackup, setFolderListBackup] = useState([]);
    const [previousFolderId, setpreviousFolderId] = useState([]);
    const [getPrivate, setgetPrivate] = useState(isPublic == 1 ? 0 : 1);

    const {
        setShow,
        getSessions,
        handleClick,
    } = useContext(SessionContext)

    useEffect(() => {
        setFolderList([])
        // if normal user get normal folders
        if (clubId?.id == 1) {
            normalUserGetFoldersToChangeAccess({ parentId: pId, isPublic: getPrivate == 1 ? 0 : 1, userId })
                .then(res => {
                    setFolderList(res.data.folders)
                }).catch(error => console.log)
        } else {
            clubId?.id && getFoldersToChangeAccess({ parentId: pId, clubId: clubId?.id, isPublic: getPrivate == 1 ? 0 : 1, userId: coachId || userId })
                .then(res => {
                    setFolderList(res.data.folders)
                }).catch(error => console.log)
        }
        setToPublic(getPrivate == 1 ? 0 : 1);
    }, [pId, getPrivate, clubId])

    const saveChanges = () => {
        // move session to current folder
        // dispatch(changeAccess({ id: data.id, isPublic, folderId}))
        dispatch(changeAccess({ id: data.id, isPublic: toPublic, folderId }))
        if (toggleView) {
            handleApiCall()
        } else {
            getSessions();
        }
        setShow(false)
        dispatch(setToast("Session Moved Successfully!"))
        handleClick();
    }

    const handleCreateClick = () => {
        setaddFolder(true);
        // setShow(1)
    }

    const handleFolderSelect = (subFolder) => {
        setfolderId(subFolder.id)
        setpId(subFolder.id)
        setpreviousFolderId([...previousFolderId, subFolder.id])
    }

    const handleSearch = (e) => {
        setFolderList(folderlistBackup.filter((row, i) => row.name.toUpperCase().includes(e.target.value.toUpperCase())))
    }

    const handleBackClick = () => {
        let arr = [...previousFolderId];
        if (arr.length) {
            arr.pop()
            setpreviousFolderId(arr);
            setpId(arr[arr.length - 1])
            if(!arr.length){
                setfolderId(0);
            }
        } else {
            setShow(1);
            setpId(undefined)
        }
    }

    return (
        <>
            {!addFolder ? <div className='newDropdownLayout_Parent'>
                <div className='header'>
                    <button className='back' onClick={() => handleBackClick()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M6.9 10.996h11.596a.75.75 0 0 1 0 1.5H6.9l4.377 4.377a.75.75 0 0 1-1.061 1.06l-4.95-4.95a1.75 1.75 0 0 1 0-2.474l4.95-4.95a.75.75 0 1 1 1.06 1.06L6.9 10.996z" fill="currentColor"></path></svg>
                    </button>
                    <h3>{INFO.MOVE_TO_FOLDER}</h3>
                </div>
                <div className='searcArea'>
                    <svg className='searchIcon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15.2 16.34a7.5 7.5 0 1 1 1.38-1.45l4.2 4.2a1 1 0 1 1-1.42 1.41l-4.16-4.16zm-4.7.16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
                    <input type="search" className='search' placeholder='Search all folders' onChange={(e) => { handleSearch(e) }} onClick={() => setFolderListBackup(folderlist)} maxLength={20}/>
                </div>
                <div className='folderList'>
                    {folderlist && folderlist.map((row, index) => {
                        return <div className='folder' key={index} onTouchStart={() => handleFolderSelect(row)} onClick={() => handleFolderSelect(row)} onDoubleClick={() => handleFolderSelect(row)}>
                            <div className='folder-icon'>
                               <div className='icon'>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.078 5.333H5.334A2.667 2.667 0 0 0 2.667 8v2.667c0 .203.023.402.066.592a2.315 2.315 0 0 0-.066.55v12.382c0 1.367 1.194 2.476 2.667 2.476h21.333c1.473 0 2.667-1.109 2.667-2.477V11.81c0-1.368-1.194-2.477-2.667-2.477h-9.333l-.726-2.176a2.667 2.667 0 0 0-2.53-1.824Zm-8.744 4h9.892L14.71 7.79a.667.667 0 0 0-.633-.456H5.334A.667.667 0 0 0 4.667 8v1.411c.213-.05.436-.078.667-.078Zm-.667 2.477c0-.229.267-.477.667-.477h21.333c.4 0 .667.248.667.477v12.38c0 .229-.267.477-.667.477H5.334c-.4 0-.667-.248-.667-.477V11.81Z" fill="currentColor"></path></svg>
                               </div>
                            </div>
                            <div className='folder-details'>
                                <p className='name'>{row.name} <span className='meta-items'>{row.item_counts} Folder</span></p>
                            </div>
                            <div className='arrow'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path></svg>
                            </div>
                        </div>
                    })}
                </div>
                <div className='footerBtns'>
                    <button className='btn create' onClick={() => { handleCreateClick() }}>+ Create new</button>
                    <button className='btn move' disabled={folderId==0} onClick={() => saveChanges()}>Move to folder</button>
                </div>
            </div> :
            <SubClubs folderParent={folderId} setFolderList={setFolderList} setshowSubClub={setaddFolder} isPublicOverride={toPublic == 1 ? 1 : 0} saveChanges={saveChanges} sessionData={data} getSessions={handleApiCall ? handleApiCall : getSessions}/>}
        </>
    )
}
