import {EDIT_USER_DETAILS, IS_ADMIN} from "../constants"


export default (user = {}, action) => {
    switch (action.type) {
      case "userId":
        return {...user,id:action.payload.id, email:action.payload.email, name:action.payload.name}
      case IS_ADMIN:       
        return { ...user, isAdmin:action.payload}
      case EDIT_USER_DETAILS:
        return {...user, name: action.payload.userName, profilePic: action.payload.image}
      // case UPDATE:
      //   return posts.map((post) => (post._id === action.payload._id ? action.payload : post));
      // case DELETE:
      //   return posts.filter((post) => post._id !== action.payload);
      default:
        return user;
    }
  };
  