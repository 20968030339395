import { SET_TOAST, UNSET_TOAST  } from '../constants';


export const setToast = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_TOAST, payload });
  } catch (error) {
    console.log(error);
  }
};


export const unsetToast = () => async (dispatch) => {
    try {
      dispatch({ type: UNSET_TOAST });
    } catch (error) {
      console.log(error);
    }
  };