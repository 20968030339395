import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { setFilter, resetFilter } from '../../actions/filter'
import { setToggle } from "../../actions/toggleView"
import { setParam } from '../../actions/sortParam'
import makeAnimated from "react-select/animated"
import { components } from "react-select";
import MySelect from "./../../assets/selectjs/MySelect";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export default function Options({ inDashboard, clubCoach, inShared, inClub, myArea }) {
    const toggleView = useSelector(state => state.toggleView)
    const clubMembers = useSelector(state => state.club?.members)
    const isPublic = useSelector(state => state.isPublic)
    const sortLabel = useSelector(state => state.sortParam?.sortLabel)
    const pId = useSelector(state => state.folder.pId)
    const clubId = useSelector((state) => state.club.details);
    const filter = useSelector((state) => state.filter);
    const [showSelected, setshowSelected] = useState([])
    const dispatch = useDispatch()
    const [showclubUsers, setshowclubUsers] = useState([])

    useEffect(() => {
        if (filter === null) {
            setshowSelected([])
        }
    }, [filter])

    useEffect(() => {
        // console.log(clubMembers);
        if (clubMembers.length > 0) {
            let option = clubMembers?.map((member, index) => {
                return { value: member.id, label: member.username }
            })
            setshowclubUsers(option)
            // setshowSelected([{ label: "Select all", value: "*" }, ...option])
        }
    }, [clubMembers])

    const showFilter = () => {
        if ((isPublic && inClub && !clubCoach && clubId.id !== 1) || (inShared && clubId.id !== 1)) {
            return true
        } else {
            return false
        }
    }
    const handleChange = selected => {
        setshowSelected(selected)
        if (selected.length > 0) {
            var allSelected = selected.filter((obj) => obj.value !== "*")
            var string = allSelected.map((data) => data.value).join(',')
        } else {
            var string = null;
        }
        dispatch(setFilter(string))
    };

    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const allOption = {
        label: "Select all",
        value: "*"
    };

    const ValueContainer = ({ children, ...props }) => {
        const currentValues = props.getValue();
        let toBeRendered = children;
        if (currentValues.some(val => val.value === allOption.value)) {
            toBeRendered = [[children[0][0]], children[1]];
        } else {
            if (children[0] !== null)
                if (children[0].length !== undefined) {
                    toBeRendered = children[0].length > 1 ? [[children[0][0]], '+' + (children[0].length - 1)] : [[children[0][0]]];
                }
        }

        return (
            <components.ValueContainer {...props}>
                {toBeRendered}
            </components.ValueContainer>
        );
    };


    const MultiValue = props => {
        let labelToBeDisplayed = `${props.data.label} `;
        if (props.data.value === allOption.value) {
            labelToBeDisplayed = "All";
        }
        return (
            <components.MultiValue {...props}>
                <span>{labelToBeDisplayed}</span>
            </components.MultiValue>
        );
    };

    const animatedComponents = makeAnimated();

    return (
        <div className="dropdown-parent">
            {(myArea || inDashboard || inClub && !pId) && <BootstrapSwitchButton checked={toggleView} onlabel='Folder View' offlabel='Session View'
                onChange={(checked) => { dispatch(setToggle(checked)) }} size="xs"
            />}
            {(showFilter() && showclubUsers && !pId) && <MySelect className="filter-select" options={showclubUsers} isMulti closeMenuOnSelect={false}
                hideSelectedOptions={false} components={{ Option, MultiValue, ValueContainer, animatedComponents }}
                onChange={handleChange} allowSelectAll={true} value={showSelected} />}

            {/* {showFilter() ? <DropdownButton id="dropdown-item-button" title={showTitle}>
                <Dropdown.Item onClick={() => { setshowTitle('All'); dispatch(resetFilter()) }} as="button">All</Dropdown.Item>
                {clubMembers?.map((member, index) => {
                    return <Dropdown.Item key={index} onClick={() => { setshowTitle(member.username); dispatch(setFilter(member.id)) }} as="button">{member.username}</Dropdown.Item>
                })}
            </DropdownButton> : <></>} */}
            <DropdownButton id="dropdown-item-button" title={sortLabel}>
                {
                    clubCoach ?
                        <>
                            <Dropdown.Item onClick={() => dispatch(setParam(6))} as="button">A -&gt; Z</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(setParam(5))} as="button">Z -&gt; A</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(setParam(3))} as="button">Created date</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(setParam(7))} as="button">Modified date</Dropdown.Item>

                        </>
                        :
                        <>
                            <Dropdown.Item onClick={() => dispatch(setParam(1))} as="button">A -&gt; Z</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(setParam(2))} as="button">Z -&gt; A</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(setParam(3))} as="button">Created date</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(setParam(4))} as="button">Modified date</Dropdown.Item>
                        </>
                }

                {/* <Dropdown.Item onClick={() => dispatch(setParam(3))} as="button">Created date</Dropdown.Item>
                {
                    // !clubCoach &&
                    <Dropdown.Item onClick={() => dispatch(setParam(4))} as="button">Modified date</Dropdown.Item>
                } */}
            </DropdownButton>
        </div>
    )
}
