import React, { useContext, useEffect } from 'react'
import moment from "moment";
import Edit from '../Edit/Edit.js';
import Dropdown from './Dropdown.js';
import Share from '../Share/Share.js';
import * as api from '../../apis/index.js';
import { Button, Image } from 'react-bootstrap';
import { setToast } from '../../actions/toast.js';
import { AiOutlineCalendar } from "react-icons/ai";
import { Download } from '../Download/Download.js';
import { Pich } from "../../assets/constants/img.js";
import { useDispatch, useSelector } from 'react-redux';
import { deleteSession } from '../../actions/sessionList';
import ChangeAccess from '../ChangeAccess/ChangeAccess.js';
import { copySession } from '../../actions/sessionList.js';
import { SessionContext } from '../Context/SessionContext.js';

export default function Session({ data, key, setShowMember, handleApiCall }) {
    const userId = useSelector(state => state.user?.id)
    const dispatch = useDispatch()
    const sessionUrl = `http://legacy.touchtight.com/session-planner-new?yours=planner&sessionId=${data.id}&sessionName=${data.session_name}&pitchStatus=${data.pitch_status}`
    const thumbnail = data.thumbnail_path !== null ? data.thumbnail_path : "bad_path"
    const pdfUrl = `http://legacy.touchtight.com/components/com_sessioncreatorv2/assets/images/session_pdf/${data.user_id}/session_${data.id}/${data.session_name}.pdf`

    const {
        getSessions,
        show, setShow,
        handleDropdownClick, 
        showEdit, setShowEdit,
        showShare, setShowShare,
        showDropdown, setShowDropdown,
    } = useContext(SessionContext)

    const handleDropdownOpen = (e, id) => {
        e.stopPropagation()
        setShowDropdown({ id, status: true });
        setShow(1);
    }

    useEffect(() => {
        if (show === 4 && showDropdown.id === data.id){
            copySessionHandler();
            setShow(0)
        }
        
        if( show ===5 && showDropdown.id === data.id) {
            deleteSessionHandler();
            setShow(0)
        }
    }, [show]);

    const copySessionHandler = async () => {
        let date = new Date();
        let sessiondata = JSON.stringify(data);
        date = date.toISOString().slice(0, 10)
        sessiondata = JSON.parse(sessiondata);
        sessiondata.session_date = date;
        sessiondata.session_name = "Copy of " + data?.session_name;
        let response = await api.copySession({ sessionId: data.id, formData : { session_name: "Copy of " + data?.session_name }, sessiondata });
        dispatch(copySession({ sessionId: data.id, formData: { session_name: "Copy of " + data?.session_name }, sessiondata }))
        if (response.status === 200) {
            if (handleApiCall) {
                handleApiCall();
            } else {
                getSessions();
            }
        }
    }

    const deleteSessionHandler = async () => {
        let response = await api.deleteSession(data.id);
        dispatch(deleteSession(data.id));
        dispatch(setToast("Session Deleted Successfully!"))
        if (response.status === 200 ) {
            if (handleApiCall) {
                handleApiCall();
            } else {
                getSessions();
            }         
        }
      
    }

    return (
        <div className="main-inner-block" key={key} onClick={(e) => { handleDropdownClick(e, "") }}>
            <div className="inner-image-section" onClick={() => window.open(sessionUrl, "_blank", `resizable=yes,scrollbars=yes,status=yes,height=${window.screen.availHeight},width=${window.screen.availWidth}`)}>
                <Image src={thumbnail} onError={(e) => { e.target.onerror = null; e.target.src = Pich }} />
            </div>
            <Button onClick={(e) => handleDropdownOpen(e, data.id)} className="btn-ellipse">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ellipsis-h"><path fill="#fff" d="M12,10a2,2,0,1,0,2,2A2,2,0,0,0,12,10ZM5,10a2,2,0,1,0,2,2A2,2,0,0,0,5,10Zm14,0a2,2,0,1,0,2,2A2,2,0,0,0,19,10Z"></path></svg>
            </Button>
            <div className="bottom-block-section">
                <Button className="cale-btn">< AiOutlineCalendar />
                    <span>{moment(data.session_date).format('DD-MM-YYYY')}</span>
                </Button>
                <h4>
                    <span title="" onClick={() => window.open(sessionUrl, "_blank", `resizable=yes,scrollbars=yes,status=yes,height=${window.screen.availHeight},width=${window.screen.availWidth}`)}>{data.session_name}</span>
                </h4>
                <p className="created-by"><i>Created by: {userId === data.user_id ? "You" : data.username}</i></p>

                {showDropdown.id === data.id && showDropdown.status && show !== 0 && <div className="block-footer block-footer-dropdown">
                    {show === 1 && <Dropdown data={data} key={key} />}
                    {show === 2 && <ChangeAccess data={data} handleApiCall={handleApiCall} />}
                    {show === 3 && <Download data={data} />}
                </div>}
            </div>

            {showShare.id === data.id && showShare && <Share setshowMember={setShowMember} folderName={data.session_name} setShowShare={setShowShare} sessionId={data.id} folderId={data.folder_id} isSession={true} sharedUserID={data.sharedUserID} />}
            {showEdit.id === data.id && showEdit && <Edit data={data} showEdit={showEdit} setshowEdit={setShowEdit} />}
        </div >
    )
}