// import LOGO from '../img/logo.png';
import PROFILE from '../img/profile.png';
import LOGO from '../img/logo.png';
import PICH from '../img/pich.png';
import FOLDER from '../img/folder.png';
import USER from '../img/user.png';
import MOVE_FOLDER_IMAGE from '../img/Move_folder_icon.png';
import DOWNLAOD_PNG_IMAGE from "../img/download.png";
import PDF_FILE_IMAGE from "../img/file.png";

// export const Logo = LOGO;
export const ProfileImage = PROFILE;
export const Logo = LOGO;
export const Pich = PICH;
export const FolderImg = FOLDER;
export const user = USER;
export const MOVE_FOLDER = MOVE_FOLDER_IMAGE;
export const DOWNLAOD_PNG = DOWNLAOD_PNG_IMAGE;
export const PDF_FILE = PDF_FILE_IMAGE;