import { SET_PARAM, RESET_PARAM} from '../constants';


export default (param ={session:"id desc", folder:"id desc", sortLabel:"Sort"}, action) => {
    switch (action.type) {
      case SET_PARAM:
        return {session:action.payload.session, folder:action.payload.folder,sortLabel:action.payload.label};
      case RESET_PARAM:
        return {session:"id desc", folder:"id desc", sortLabel:"Sort"};
      default:
        return param;
    }
  };
  