import React, { useEffect, useState } from 'react';
import "./Folder.css";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import FolderCard from '../Cards/FolderCard';
import { setParent } from '../../actions/folder';
import { resetFilter } from '../../actions/filter';
import { resetParam } from '../../actions/sortParam';
import { normalUserFolders, subClubDetails } from '../../apis';
import { folderList, clearFolderList } from '../../actions/folderList';


export default function Folder({ clubCoach, setpath, setfolderId, setfolderName, setShowShare, folderLoading, setfolderLoading, sessionLoading, setsharedUserID, fromSessionFolder }) {
    const dispatch = useDispatch();
    const [count, setcount] = useState(0);
    const [folderlist, setFolderList] = useState([]);
    const filter = useSelector(state => state.filter);
    const pId = useSelector(state => state.folder.pId);
    const userId = useSelector(state => state.user?.id);
    const folders = useSelector(state => state.folderList);
    var isPublicState = useSelector(state => state.isPublic);
    const clubId = useSelector((state) => state.club.details);
    const coachId = useSelector((state) => state.coachId?.id);
    const sortParam = useSelector((state) => state.sortParam.folder);
    const isPublic = clubCoach || fromSessionFolder ? isPublicState : 1;

    useEffect(() => {
        setpath([])
        dispatch(resetFilter())
        dispatch(resetParam())
        setfolderLoading(true);
        dispatch(setParent(undefined));
        dispatch(clearFolderList())
    }, [isPublic])

    useEffect(() => {
        setfolderLoading(true);
        dispatch(clearFolderList())
    }, [pId])

    useEffect(() => {
        // if normal user get normal folders
       
        if (clubId?.id == 1) {
            normalUserFolders({ parentId: pId, isPublic, userId, sortParam, filter, clubCoach })
                .then(res => {
                    dispatch(folderList(res.data.folders))
                    setfolderLoading(false); setFolderList(res.data.folders);
                    setcount(0)
                }).catch(error => console.log)
        } else {
            clubId?.id && subClubDetails({ parentId: pId, clubId: clubId?.id, isPublic, userId: coachId || userId, sortParam, filter: fromSessionFolder ? userId : filter, clubCoach })
                .then(res => {
                    dispatch(folderList(res.data.folders))
                    setfolderLoading(false); setFolderList(res.data.folders);
                    setcount(0)
                }).catch(error => console.log)
        }

    }, [pId, isPublic, clubId, sortParam, filter])

    const handleClick = (subFolder) => {
        dispatch(clearFolderList())
        dispatch(setParent(subFolder.id));
        setpath(path => [...path, subFolder])
        setcount(1)
    }

    if (!clubId?.id) {
        return (
            <p className="loader-p">No Club Created</p>
        )
    }
    if (folderLoading) {
        return (
            <p className="loader-p">Loading...</p>
        )
    }


    if (folders.length === 0 && !sessionLoading.loading && !folderLoading && sessionLoading.length == 0) {
        return (
            <p className="loader-p">No Content</p>
        )
    }

  
    return (<>
        <FolderCard setsharedUserID={setsharedUserID} folderlist={folderlist} count={count} setfolderName={setfolderName} setfolderId={setfolderId} setShowShare={setShowShare} handleClick={handleClick} />
    </>
  )
}
