import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { clubDetails, clubSessions } from '../../apis';
import Header from '../Header/Header';
import Share from '../Share/Share';
import SubClubs from '../SubClubs/SubClubs'
import Folder from '../Folder/Folder';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setParent } from '../../actions/folder';
import { setActiveSidebar } from '../../actions/sidebar';
import { resetToggle } from "../../actions/toggleView"



import Path from '../Path/Path';
import Sessions from '../Sessions/Sessions';

export default function MyArea({ setmyArea, setinClub, setinShared }) {
    const [path, setpath] = useState([])
    const pId = useSelector(state => state.folder.pId)
    const club = useSelector((state) => state.club);
    const [folderName, setfolderName] = useState("")
    const [folderLoading, setfolderLoading] = useState(true)
    const [sessionLoading, setsessionLoading] = useState({ loading: true, length: 0 })
    const dispatch = useDispatch();
    const [sessions, setsessions] = useState({ sessionData: [], loading: true, message: '', page: 1, offset: 0, limit: 12, count: 1, private: true })
    const [showShare, setshowShare] = useState(false)
    const [folderId, setfolderId] = useState("")
    const toggleView = useSelector(state => state.toggleView)


    useEffect(() => {
        dispatch(setActiveSidebar(4))
        window.scrollTo({ top: 0, left: 0 });
        setinShared(false)
        setinClub(false)
        setmyArea(true)
        return () => {           
            dispatch(resetToggle())
            setmyArea(false)
        }
    }, [])

    const handlePath = (p, limit) => {
        if (limit === "clear") {
            setpath([])
        } else {
            let count = 0
            let newPath = path.filter((inner) => {
                if (count !== limit) {
                    count += 1
                    return inner
                }
            })
            setpath(newPath)
        }
    }

    return (
        <div className="folder-path-parent">

            {/* <div className="folder-path">
                {
                    path.map((p, index) => <span key={index} onClick={() => { dispatch(setParent(p.parent_id)); handlePath(p, index) }}><span className="folder-name">&lt;{p.name}</span> </span>)
                }
            </div> */}
            <Path handlePath={handlePath} path={path} title={"My Area"} />
            {/* 
                if new to club and admin show the club creation form
            */}

            {/* folders */}
           {toggleView && <Folder folderLoading={folderLoading} setfolderLoading={setfolderLoading} sessionLoading={sessionLoading} setfolderName={setfolderName} setshowShare={setshowShare} setfolderId={setfolderId} setpath={setpath} />}
            { (pId !== undefined || !toggleView) && <Sessions folderLoading={folderLoading} sessionLoading={sessionLoading} setsessionLoading={setsessionLoading} />}
            {showShare && <Share folderName={folderName} setshowShare={setshowShare} folderId={folderId} />}
        </div >
    )
}
