import React, { useState, useSelector } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { deleteSession } from '../../actions/sessionList';
import { deleteFolder } from '../../actions/folderList.js';
import {setToast} from '../../actions/toast'
import { getClubSessions, getSharedSessions, normalSessions } from "../../apis";



export default function Delete({ session, setshowsessionDelete, folder, folderDetails,  getSessions }) {
    const dispatch = useDispatch()
    const [show, setshow] = useState(true)
   
    const deleteSessionHandler = () => {
        if (folder) {
            dispatch(deleteFolder(folderDetails.id))
        } else {
            dispatch(deleteSession(session.id))
        }
        setshowsessionDelete(false)
        setshow(false)
        dispatch(setToast("Session Deleted Successfully!"))
        if (getSessions) {
            getSessions();           
        }
    }

    const handleClose = () => {
        setshowsessionDelete(false)
        setshow(false)
    }
    return (
        <Modal show={show} onHide={handleClose} className="folder-modal club-description-form delete-popup">
            <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this {folder ? 'folder' : 'session'}?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button variant="primary" onClick={deleteSessionHandler}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
