import { ACTIVE_SIDEBAR  } from '../constants';


export const setActiveSidebar = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVE_SIDEBAR, payload: data });
    } catch (error) {
        console.log(error);
    }
};
