import React, { useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Image, Modal, Button } from "react-bootstrap";
import { editSession } from "../../actions/sessionList";
import { editDetails } from "../../actions/user";
import { setToast } from "../../actions/toast";
import { useDispatch } from "react-redux";
import FileBase64 from 'react-file-base64';
import { ProfileImage, Logo } from "../../assets/constants/img.js";
import { useSelector } from "react-redux";

export default function Profile({ avtar, setupdate, showProfile, setshowProfile }) {
  const user = useSelector((state) => state.user);
  const clubName = useSelector((state) => state.club?.details?.name);
  const [userDetails, setuserDetails] = useState({
    name: user.name,
    email: user.email,
    clubName: clubName,
  });

  const [error, seterror] = useState(false);
  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();

  const handleClose = () => {
    setshowProfile(false);
  };
  const [image, setImage] = useState("");

  const saveUserDetails = async () => {
    // upload();
    if (userDetails.name.trim() !== "") {
      await dispatch(editDetails({ userId, userName: userDetails.name, image }));
      setupdate(update => !update)
      setshowProfile(false);
      await dispatch(setToast("Profile updated Successfully!"));
    } else {
      seterror(true);
    }
  };

  return (
    <Modal
      show={showProfile}
      onHide={handleClose}
      className="folder-modal club-description-form edit-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title> Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p>ALL FEILDS ARE REQUIRED</p>}
        <div className={"club-desc-row profile-box"}>
          <Image
            className={"edit-user-profile-image"}
            src={avtar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = ProfileImage;
            }}
          />
          <div className="img-uploader">
            <button className={"upload-btn btn"}>
              <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1"><g><g transform="translate(-865.000000, -443.000000)" fill="#FFFFFF"><g transform="translate(690.000000, 308.000000)"><g transform="translate(160.000000, 126.000000)"><path d="M20.5,12.2 C20.5,11.7584 20.8584,11.4 21.3,11.4 C21.7416,11.4 22.1,11.7584 22.1,12.2 C22.1,12.6416 21.7416,13 21.3,13 C20.8584,13 20.5,12.6416 20.5,12.2 L20.5,12.2 Z M29.4,22.6 C29.4,23.0416 29.0416,23.4 28.6,23.4 L27.8,23.4 L27.8,20.2 C27.8,19.316 27.084,18.6 26.2,18.6 L19.8,18.6 C18.916,18.6 18.2,19.316 18.2,20.2 L18.2,23.4 L17.4,23.4 C16.9584,23.4 16.6,23.0416 16.6,22.6 L16.6,14.6352 C16.6,14.5288 16.6424,14.4272 16.7168,14.3528 L18.2,12.8696 L18.2,13.8 C18.2,14.684 18.916,15.4 19.8,15.4 L26.2,15.4 C27.084,15.4 27.8,14.684 27.8,13.8 L27.8,10.6 L28.6,10.6 C29.0416,10.6 29.4,10.9584 29.4,11.4 L29.4,22.6 Z M26.2,23.4 L19.8,23.4 L19.8,21 C19.8,20.5584 20.1584,20.2 20.6,20.2 L25.4,20.2 C25.8416,20.2 26.2,20.5584 26.2,21 L26.2,23.4 Z M19.8,11.2696 L20.4696,10.6 L26.2,10.6 L26.2,13 C26.2,13.4416 25.8416,13.8 25.4,13.8 L20.6,13.8 C20.1584,13.8 19.8,13.4416 19.8,13 L19.8,11.2696 Z M29.4,9 L20.1312,9 C19.9192,9 19.716,9.084 19.5656,9.2344 L15.2344,13.5656 C15.084,13.716 15,13.9192 15,14.1312 L15,23.4 C15,24.284 15.716,25 16.6,25 L29.4,25 C30.284,25 31,24.284 31,23.4 L31,10.6 C31,9.716 30.284,9 29.4,9 L29.4,9 Z"></path></g></g></g></g></svg>
              Upload Image
            </button>
            <FileBase64
              multiple={true}
              onDone={(files) => { setImage(files) }} />
          </div>
        </div>

        <div className={"club-desc-row"}>
          <label>Name: </label>
          <input
            type="text"
            placeholder="Enter session title"
            value={userDetails.name}
            onChange={(e) => {
              setuserDetails({ ...userDetails, name: e.target.value });
              seterror(false);
            }}
          />
        </div>
        <div className={"club-desc-row"}>
          <label>Club Name: </label>
          <input
            type="text"
            placeholder="Enter Club Name "
            value={userDetails.clubName}
            onChange={(e) => {
              setuserDetails({ ...userDetails, clubName: e.target.value });
              seterror(false);
            }}
            readOnly={true}
          />
        </div>
        <div className={"club-desc-row"}>
          <label>Email: </label>
          <input
            type="text"
            placeholder="Enter Email "
            value={userDetails.email}
            onChange={(e) => {
              setuserDetails({ ...userDetails, email: e.target.value });
              seterror(false);
            }}
            readOnly={true}
          />
        </div>
        <div className={"club-desc-row"}>
          <label>User Role: </label>
          <input
            type="text"
            value={user.isAdmin ? "Admin" : "Coach"}
            readOnly={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={saveUserDetails}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
