import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image, Button, ListGroup } from "react-bootstrap";
import { MdModeEdit, MdShare, MdOndemandVideo, MdApps, } from "react-icons/md";
import { AiOutlinePoweroff } from "react-icons/ai";
import { ProfileImage } from "../../assets/constants/img.js";
import "./Sidebar.css";
import { useSelector } from "react-redux";
import LogOut from "../LogOut/LogOut.js";
import Profile from "../Profile/Profile.js";
import Save from '../Clubs/Form/Save';

export default function Sidebar({ setshowSideBar, matchWidth, showClub}) {
  const club = useSelector(state => state.club.details)
  const isPublic = useSelector(state => state.isPublic)
  const pId = useSelector(state => state.folder.pId)
  const userId = useSelector(state => state.user.id)
  const user = useSelector(state => state.user)
  const [showLogOutPopUp, setshowLogOutPopUp] = useState(false)
  const [showClubForm, setshowClubForm] = useState(false)
  const [showProfile, setshowProfile] = useState(false)
  const showTab = useSelector(state => state.sidebar)
  const [avtar, setavtar] = useState(`https://firebasestorage.googleapis.com/v0/b/touchtight-c809b.appspot.com/o/images%2F${userId}?alt=media`)
  const [update, setupdate] = useState(false)
  useEffect(() => {
    setavtar(`https://firebasestorage.googleapis.com/v0/b/touchtight-c809b.appspot.com/o/images%2F${userId}?alt=media&randStr=${Math.random()}`)
  }, [update])

  const addUrl = `http://legacy.touchtight.com/session-planner-new?yours=planner`
  const oldSessionPlanner = 'https://legacy.touchtight.com/legacy-session-planner'
  return (
    <div className="sidebar" onClick={() => { matchWidth && setshowSideBar(false) }}>

      <MdModeEdit onClick={() => setshowProfile(true)} className="edit-profile-btn" />

      <div className="sidebar-bg"></div>
      <div className="sidebar-overlay"></div>
      <div className="top-sidebar">
      </div>
      <div className="sidebar-header">
        <Image src={avtar} onError={(e) => { e.target.onerror = null; e.target.src = ProfileImage }} />
        <div className="profile-detail">
          <p>{club?.name}</p>
          <h4>{user.name}</h4>
          <Button>{user.isAdmin ? "Admin" : "Coach"}</Button>
        </div>
      </div>
      <div className="sidebar-listing">
        <ListGroup defaultActiveKey="#link1">
          <ListGroup.Item className={showTab === 1 ? "active" :""}>
            <Link to="/" >
              <MdApps />
              My Sessions

            </Link>
          </ListGroup.Item>
          <ListGroup.Item className={showTab === 2 ? "active" :""}>
            <Link to="/shared">
              <MdShare /> Shared Sessions
            </Link>
          </ListGroup.Item>
          <ListGroup.Item onClick={() => window.open(addUrl, "_blank", `resizable=yes,scrollbars=yes,status=yes,height=${window.screen.availHeight},width=${window.screen.availWidth}`)}>
            <MdOndemandVideo />
            Add New Session
          </ListGroup.Item>

          {(club?.id != 1 || club?.id == 1 && user.isAdmin) && <ListGroup.Item className={showTab === 3 ? "active": ""} onClick={club?.id == 1 ? () => setshowClubForm(true):() => setshowClubForm(false)}>
            <Link to="/clubs" >
              <MdOndemandVideo />
              Club
            </Link>
          </ListGroup.Item>}
          {(club?.id == 1 && !user.isAdmin) && <ListGroup.Item className={showTab === 4 ? "active" : ""}>
            <Link to="/my-area">
              <MdOndemandVideo />
              My Area
            </Link>
          </ListGroup.Item>}
          {user.isAdmin && <ListGroup.Item className={showTab === 5 ? "active" :""}>
            <Link to="/club-coaches">
              <MdOndemandVideo />
              Club Coaches
            </Link>
          </ListGroup.Item>}
          <ListGroup.Item onClick={() => setshowLogOutPopUp(true)}>
            <>
              <AiOutlinePoweroff />
              Logout
            </>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div className="sidebar-footer">
        <Button className="old-session-planner-btn" onClick={() => window.open(oldSessionPlanner, "_blank")}>Old Session Planner</Button>
      </div>
      {(showClubForm) && <Save setshowClubForm={setshowClubForm} />}
      {showLogOutPopUp && <LogOut showLogOutPopUp={showLogOutPopUp} setshowLogOutPopUp={setshowLogOutPopUp} />}
      {showProfile && <Profile avtar={avtar} setupdate={setupdate} showProfile={showProfile} setshowProfile={setshowProfile} />}
    </div>
  );
}
