import React, { useState, useEffect } from 'react'
import Path from '../Path/Path';
import Share from '../Share/Share';
import { getShared } from '../../apis';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { subClubDetails } from '../../apis';
import Sessions from '../Sessions/Sessions';
import { INFO } from '../../utils/constants';
import FolderCard from '../Cards/FolderCard';
import { setParent } from '../../actions/folder';
import { resetFilter } from '../../actions/filter';
import { folderList } from '../../actions/folderList';
import { setActiveSidebar } from '../../actions/sidebar';
import { SessionContext } from '../Context/SessionContext';


export default function Shared({ setinShared, setinClub }) {
    const dispatch = useDispatch();
    const [show, setShow] = useState(0);
    const [path, setpath] = useState([]);
    const [count, setcount] = useState(0);
    const [folderId, setfolderId] = useState("");
    const [loading, setloading] = useState(true);
    const [folderlist, setFolderList] = useState([]);
    const [folderName, setfolderName] = useState("");
    const filter = useSelector(state => state.filter);
    const [showShare, setShowShare] = useState(false);
    const pId = useSelector(state => state.folder.pId);
    const userId = useSelector(state => state.user?.id);
    const clubId = useSelector((state) => state.club.details);
    const sortParam = useSelector((state) => state.sortParam.folder);
    const [showEdit, setShowEdit] = useState({ id: 0, status: false });
    const [showDropdown, setShowDropdown] = useState({ id: 0, status: false });
    const [sessionLoading, setsessionLoading] = useState({ loading: true, length: 0 });

    useEffect(() => {
        dispatch(setParent(undefined));
    }, [])
    useEffect(() => {
        dispatch(setActiveSidebar(2))
        dispatch(resetFilter())
        setinShared(true)
        setinClub(false)
        setpath([])
        return () => {
            setpath([])
            dispatch(resetFilter())
            setinShared(false)
        }
    }, [clubId])


    useEffect(() => {
        if (path.length > 0) {
            clubId?.id && subClubDetails({ parentId: pId, clubId: clubId?.id, sortParam, filter })
                .then(res => {
                    dispatch(folderList(res.data.folders))
                    setloading(false);
                    setFolderList(res.data.folders);
                    setcount(0)
                }).catch(error => console.log)
        } else {
            clubId?.id && getShared({ userId, sortParam, filter })
                .then((res) => {
                    dispatch(folderList(res.data.getShared))
                    setloading(false);
                    setFolderList(res.data.getShared);
                    setcount(0)
                })
                .catch((error) => {
                })
        }

    }, [pId, clubId, filter, sortParam])


    const handleClick1 = (subFolder) => {
        dispatch(setParent(subFolder.id));
        setpath(path => [...path, subFolder])
        setcount(1)
    }

    const handlePath = (p, limit) => {
        if (limit === "clear") {
            setpath([])
        } else {
            let count = 0
            let newPath = path.filter((inner) => {
                if (count !== limit) {
                    count += 1
                    return inner
                }
            })
            setpath(newPath)
        }

    }
    if (loading) {
        return (
            <p className="loader-p">Loading...</p>
        )
    }
    if (folderlist.length <= 0 && !sessionLoading.loading && sessionLoading.length === 0) {
        return (
            <div className="folder-path-parent">
                <Path handlePath={handlePath} path={path} title={"Shared Folders"} />
                <p className="loader-p">No Content</p>
            </div>
        )
    }

    const handleDropdownClick = (e, data) => {
        e.stopPropagation();
        if (data === INFO.MOVE_TO_FOLDER) {
            setShow(2);
        } else if (data === INFO.SHARE_SESSION) {
            setShowShare({ id: showDropdown.id, status: true })
            setShow(0)
        } else if (data === INFO.DOWNLOAD) {
            setShow(3)
        } else if (data === INFO.MAKE_A_COPY) {
            setShow(4)
        } else if (data === INFO.EDIT) {
            setShowEdit({ id: showDropdown.id, status: true })
            setShow(0)
        } else if (data === INFO.MOVE_TO_TRASH) {
            setShow(4)
        }
    }

    const handleClick = () => {
        setShowDropdown({ id: 0, status: false })
        setShowEdit({ id: 0, status: false });
        setShowShare({ id: 0, status: false });
        setShow(0)
    }

    return (
        <SessionContext.Provider value={{
            // getSessions,
            show, setShow,
            showEdit, setShowEdit,
            showShare, setShowShare,
            showDropdown, setShowDropdown,
            handleClick, handleDropdownClick,
        }}>

            <div className="folder-path-parent" onClick={() => { handleClick() }}>
                {(folderlist.length !== 0 || sessionLoading.length !== 0) && <>
                    <Path handlePath={handlePath} path={path} title={"Shared Folders"} />
                    <FolderCard inShared={true} folderlist={folderlist} count={count} setfolderId={setfolderId} setShowShare={setShowShare} setfolderName={setfolderName} handleClick={handleClick1} />
                </>}
                <Sessions inShared={true} setsessionLoading={setsessionLoading} sessionLoading={sessionLoading} />
                {showShare?.status && <Share folderName={folderName} setShowShare={setShowShare} folderId={folderId} />}
            </div>
        </SessionContext.Provider>

    )
}
