import { clubDetails } from '../apis';
import { CLUB_DETAIL,CLUB_MEMBERS, UPDATE_CLUB_DESCRIPTION  } from '../constants';

export default (club = {members: []}, action) => {
  switch (action.type) {
    case CLUB_DETAIL:
      club.details = action.payload.details[0]
      return club;
    case CLUB_MEMBERS:
      club.members = action.payload;
      return club
    case UPDATE_CLUB_DESCRIPTION:
      return {...club, details:{...club.details, name: action.payload.name, description: action.payload.description} }
    // case UPDATE:
    //   return posts.map((post) => (post._id === action.payload._id ? action.payload : post));
    // case DELETE:
    //   return posts.filter((post) => post._id !== action.payload);
    default:
      return club;
  }
};
