import './App.css';
// import Main from './Components/Main';
import "./Components/Main.css";
import React, { useEffect, useState } from 'react'

import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Components/Dashboard/Dashboard';
import Clubs from './Components/Clubs/Clubs';
import Shared from './Components/Shared/Shared';
import Header from './Components/Header/Header';
import Create from './Components/CreateClub/Create';
import { subClubDetails } from './apis'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getUserId, isAdmin } from './actions/user';
import { getClubDetails, getMembers } from './actions/club';
import useWindowResize from './custom-hooks/useWindowResize';
import MyArea from './Components/MyArea/MyArea';
import Coaches from './Components/Coaches/Coaches';
import { Toast } from 'react-bootstrap';
import { unsetToast } from './actions/toast'
import Options from './Components/Options/Options';

function App() {
  const [inDashboard, setinDashboard] = useState(false)
  const [showClub, setShowClub] = useState(0)
  const [clubDetail, setclubDetail] = useState({})
  const [folders, setfolders] = useState([])
  const [showForm, setshowForm] = useState(false)
  const [inClub, setinClub] = useState(false)
  // const [inprivate, setprivate] = useState(false)
  const [inShared, setinShared] = useState(false)
  const [myArea, setmyArea] = useState(false)
  const [parentFolderId, setparentFolderId] = useState("")
  const [showClubForm, setshowClubForm] = useState(false)
  const [showSideBar, setshowSideBar] = useState(false)
  const [matchWidth, setmatchWidth] = useState(window.matchMedia("(max-width: 1024px)").matches)
  const userId = useSelector((state) => state.user.id);
  const user = useSelector((state) => state.user);
  const states = useSelector((state) => state);
  const admin = useSelector((state) => state.user.isAdmin)
  const clubId = useSelector((state) => state.club.details);
  const showToast = useSelector((state) => state.toast);
  const [clubCoach, setclubCoach] = useState(false)
  const [showHeader, setshowHeader] = useState(true);
  const [showCreatePopUp, setshowCreatePopUp] = useState(true)


  // const [userId, setuserId] = useState(initialState)
  // const userId = 5773
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getUserId());
    !matchWidth ? setshowSideBar(true) : setshowSideBar(false)

  }, [matchWidth]);

  useEffect(async () => {
      if (!isNaN(userId) || userId !== undefined) {
      await dispatch(isAdmin(userId));
      dispatch(getClubDetails(userId))
    }
  }, [userId])

  useEffect(() => {
    if (!isNaN(clubId) || clubId !== undefined) {
      setshowHeader(false);
      dispatch(getMembers(clubId.id))     
    }
  }, [clubId])

  const getSubClubs = async () => {
    try {
      let { data } = await subClubDetails({ userId })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    // get token from url => done!
    // verify it from backend and get the userId
    // else redirect it to the sesion planner page
  
    let token = window.location.search.trim('?token=').split('=')
     token = token[1] !== undefined ? token[1] : localStorage.getItem("token")
    dispatch(getUserId(token));
  }, [])
  useWindowResize(setmatchWidth)
  
  return (
    <Router>
      {(userId && clubId) && <Container fluid className="main-section">
        {showSideBar && <Sidebar matchWidth={matchWidth} showClub={showClub} setshowSideBar={setshowSideBar} />}
        <div className="main-right-section" onClick={() => { (matchWidth && showSideBar) && setshowSideBar(false) }}>
          <div className='main-header-section main-header-section-custom'>
            <Header inDashboard={inDashboard} clubCoach={clubCoach} myArea={myArea} setshowSideBar={setshowSideBar} inClub={inClub} showForm={showForm} inShared={inShared} setshowClubForm={setshowClubForm} parentFolderId={parentFolderId} clubDetails={clubDetail} setfolders={setfolders} />
            <Options myArea={myArea} clubCoach={clubCoach} inDashboard={inDashboard} inShared={inShared} inClub={inClub} />
          </div>
          { showCreatePopUp && clubId.id == 1 && admin &&<Create showCreatePopUp={showCreatePopUp} setshowCreatePopUp={setshowCreatePopUp} />}
          <Switch>
            <Route path="/" exact><Dashboard inDashboard={inDashboard} setinDashboard={setinDashboard} id={userId} setinClub={setinClub} setinShared={setinShared} /></Route>
            <Route path="/clubs" exact><Clubs setinShared={setinShared} showClubForm={showClubForm} setshowClubForm={setshowClubForm} setinClub={setinClub} clubDetails={clubDetail} setshowForm={setshowForm} showForm={showForm} folders={folders} /></Route>
            <Route path="/shared"><Shared setinShared={setinShared} setinClub={setinClub} /></Route>
            {(clubId.id == 1) && <Route path="/my-area"><MyArea setmyArea={setmyArea} setinShared={setinShared} setinClub={setinClub} /></Route>}
            {(admin) && <Route path="/club-coaches" exact><Coaches clubCoach={clubCoach} setclubCoach={setclubCoach} setinShared={setinShared} showClubForm={showClubForm} setshowClubForm={setshowClubForm} setinClub={setinClub} clubDetails={clubDetail} setshowForm={setshowForm} showForm={showForm} folders={folders}/></Route>}
          </Switch>
          <Toast className="toast" onClose={() => dispatch(unsetToast())} show={showToast.flag} delay={10000} autohide>
            <Toast.Body>{showToast.message}</Toast.Body>
          </Toast>
        </div>
      </Container>}
    </Router>
  );
}

export default App;
