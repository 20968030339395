import React, { useState, useEffect } from "react";
import Session from "../Cards/Session";
import { resetFilter } from "../../actions/filter";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { sessionList } from "../../actions/sessionList";
import { getClubSessions, getSharedSessions, normalSessions } from "../../apis";

function Sessions({
  inClub,
  inShared,
  clubCoach,
  folderLoading,
  setshowMember,
  sessionLoading,
  setsessionLoading,
  fromSessionFolder,
}) {

  const limit = 12;
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);
  const [count, setcount] = useState(1);
  const [offset, setoffset] = useState(0);
  const [sessionData, setsessionData] = useState([]);
  const filter = useSelector((state) => state.filter);
  const userId = useSelector((state) => state.user.id);
  const pId = useSelector((state) => state.folder.pId);
  const isPublic = useSelector((state) => state.isPublic);
  const coachId = useSelector((state) => state.coachId?.id);
  const sessions = useSelector((state) => state.sessionList);
  const toggleView = useSelector((state) => state.toggleView);
  const clubId = useSelector((state) => state.club?.details?.id);
  const sortParam = useSelector((state) => state.sortParam.session);

  useEffect(() => {
    dispatch(resetFilter());
    setsessionData([]);
  }, [isPublic]);
  
  useEffect(() => {
    dispatch(resetFilter());
    setsessionData([]);
  }, [isPublic]);

  const handleApiCall = () => {
    if (inShared && pId === undefined) {

      getSharedSessions({
        clubId,
        offset,
        limit,
        isPublic,
        userId,
        pId,
        sortParam,
        filter,
      })
        .then((response) => {

          if (response.data.responseCode === 200) {
            const { sessionList: sessionlist, totalPages } = response.data.data;
            setsessionData(sessionlist);
            dispatch(sessionList(sessionlist));
            setcount(totalPages);
            setsessionLoading({ loading: false, length: sessionlist.length });
          } else {
            setsessionLoading({ loading: false, length: 0 });
          }
        })
        .catch((error) => console.log(error));
      return () => {
        dispatch(sessionList([]));
      };
    } else {
      // if normal user then get normal folder
      if (clubId == 1) {
        normalSessions({
          clubSession: false,
          clubId,
          offset,
          limit,
          isPublic,
          userId,
          pId,
          sortParam,
          filter,
        })
          .then((response) => {
            if (response.data.responseCode === 200) {
              const { sessionList: sessionlist, totalPages } =
                response.data.data;
              setsessionData(sessionlist);
              dispatch(sessionList(sessionlist));
              setcount(totalPages);

              setsessionLoading({ loading: false, length: sessionlist.length });
            } else {
              setsessionLoading({ loading: false, length: 0 });
            }
          })
          .catch((error) => console.log);
        return () => {
          dispatch(sessionList([]));
        };
      } else {
        getClubSessions({
          clubSession: true,
          clubId,
          offset,
          limit,
          isPublic,
          userId: coachId || userId,
          pId,
          sortParam,
          inClub,
          filter: fromSessionFolder ? userId : filter,
          clubCoach,
        })
          .then((response) => {
            if (response.data.responseCode === 200) {
              const { sessionList: sessionlist, totalPages } =
                response.data.data;
              setsessionData(sessionlist);
              dispatch(sessionList(sessionlist));
              setcount(totalPages);
              setsessionLoading({ loading: false, length: sessionlist.length });
            } else {
              setsessionLoading({ loading: false, length: 0 });
            }
          })
          .catch((error) => console.log(error));
        return () => {
          dispatch(sessionList([]));
        };
      }
    }
  }

  useEffect(() => {
    setsessionLoading({ loading: true, length: 0 });
    handleApiCall()
    // if in shared and Pid is undefined  then find isolated sessions
  }, [page, clubId, offset, isPublic, userId, pId, sortParam, filter]);

  const handlePageChange = (event, value) => {
    setpage(value);
    setoffset((value - 1) * 12);
  };

  const renderSessions = () => {
    if (sessionLoading?.loading === false && sessionData?.length > 0) {
      return (
        <>
          {inShared && <span className="folder-name">Shared Sessions</span>}
          <div className="main-right-inner">
            <div className="main-right-inner-inner">
              {sessions.length > 0 &&
                sessions.map((data, index) => {
                  return (
                    <>
                      {data.session_date && <Session setshowMember={setshowMember} inShared={inShared} data={data} key={index} handleApiCall={handleApiCall} />}
                    </>
                  );
                })}
            </div>
          </div>
          {sessions.length > 0 && (
            <Pagination
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          )}
        </>
      );
    } else if (folderLoading) {
      return <p className="loader-p">Loading...</p>;
    } else if (sessions.length === 0 && sessionLoading?.loading === false) {
      return <>{toggleView ? "" : (inShared ? "" : <p className="loader-p">No Content</p>)}</>;
    }
    else {
      return <p className="loader-p">Loading...</p>;
    }
  };
  return <div>{renderSessions()}</div>;
}

export default Sessions;
