import { SET_FILTER, RESET_FILTER} from '../constants';

export const setFilter = (userId) => async (dispatch) => {
  try {
    dispatch({ type: SET_FILTER, payload: userId});
  } catch (error) {
    console.log(error);
  }
};


export const resetFilter = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_FILTER });
  } catch (error) {
    console.log(error);
  }
};
