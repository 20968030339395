import React, { useEffect, useState } from "react";
import Clubs from "../Clubs/Clubs";
import { useDispatch, useSelector } from "react-redux";
import { setCoachId, resetCoachId } from "../../actions/coachId";
import { user } from "../../assets/constants/img.js";
import { Image } from "react-bootstrap";
import { setActiveSidebar } from "../../actions/sidebar";
import { resetParam } from "../../actions/sortParam";
import { normalUserFolders, subClubDetails, getMembers } from '../../apis'


export default function Coaches({
  clubCoach,
  setclubCoach,
  showClubForm,
  setshowClubForm,
  setinClub,
  setinShared,
}) {
  const userId = useSelector((state) => state.user.id);
  const [showFolders, setshowFolders] = useState(false);
  const [showMember, setshowMember] = useState(0);
  const [memberList, setMemberList] = useState([]);
  const members = useSelector((state) => state.club?.members);  
  const sortParam = useSelector((state) => state.sortParam.session);
  const clubId = useSelector((state) => state.club.details);
  const pId = useSelector(state => state.folder.pId)
  var isPublicState = useSelector(state => state.isPublic);
//   const isPublic = clubCoach || fromSessionFolder ? isPublicState : 1
  const [folderlist, setFolderList] = useState([])
  const dispatch = useDispatch();
  const folders = useSelector(state => state.folderList)
//   const userId = useSelector(state => state.user?.id)
  const [loading, setloading] = useState(true)
  const [count, setcount] = useState(0)
  const sessionList = useSelector(state => state.sessionList)
  const filter = useSelector(state => state.filter)
//   console.log(sortParam ,"absfcv");

  useEffect(() => {
    dispatch(setActiveSidebar(5));
    setclubCoach(true);
    return () => {
      setclubCoach(false);
      dispatch(resetCoachId());
      dispatch(resetParam());
    };
  }, []);

  const handleClick = (member) => {
    dispatch(setCoachId(member));
    setshowMember(member.id);
    setshowFolders(true);  
  };

  const reset = () => {
    dispatch(resetCoachId());
    setshowFolders(false);
  };

    useEffect(() => {
      // if normal user get normal folders
      console.log(sortParam, "sort param")
      if (clubId && sortParam) {
        getAllMembers(clubId.id , sortParam)
      }
    }, [sortParam, filter]);

    const getAllMembers =(id , sortParam) => {
      getMembers(id , sortParam)
      .then((res) => {
          console.log("res : ", res);
        if(res.data) {            
            setMemberList(res.data.members)
        }
      })
      .catch((error) => console.log);
    }
    if (memberList.length === 0) {
      return (
        <> 
          <div className="folder-coaches-parent">
            {!showFolders && <span className="coach-breadcrumb">Club Coaches</span>}
          </div>
          <p className="loader-p">No Content</p>
        </>
      )
   }
//    if (loading) {
//     return (
//         <><p className="loader-p">Loading....</p></>
//     )
//  }
  return (
    <>
      {/* <button onClick={reset}>back</button>   */}
        <div className="folder-coaches-parent">
        {!showFolders && <span className="coach-breadcrumb">Club Coaches</span>}
        <div className="folder-coaches-parent-inner">
        {!showFolders &&
          memberList.length>0 &&
          memberList.map((member, index) => {
            console.log("member : ", member);
            if(member.image === '1'){
             var userImage  = `https://firebasestorage.googleapis.com/v0/b/touchtight-c809b.appspot.com/o/images%2F${member.id}?alt=media`;
             console.log("userImage===", userImage);
            }else{
             var userImage = `http://sessionplanner.touchtight.com/static/media/user.0d14c699.png`;
            }
            if (userId != member.id) {
              return (
                <div
                  className="folder-parent"
                  key={index}
                  onTouchStart={() => handleClick(member)}
                  onDoubleClick={() => handleClick(member)}
                >          
                               
                  <div>
                    <Image src={userImage} className="folderImg" />
                    <p className="blank-div"></p>
                  </div>
                  <div className="sub-folder-parent-parent">
                    <p className="foldername" title="subFolder.name">
                      {member.username}
                    </p>
                  </div>
                </div>
              );
            }
          })}
          </div>
      </div>
      {showFolders && (
        <Clubs
          clubCoach={clubCoach}
          reset={reset}
          setinShared={setinShared}
          showClubForm={showClubForm}
          setshowClubForm={setshowClubForm}
          setinClub={setinClub}
          setshowMember={showMember}
        />
      )}
    </>
  );
}
