import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { shareNow, shareSession } from '../../apis'
import { updateSharedUserID } from '../../actions/sessionList';
import { updateFolderSharedUserID } from "../../actions/folderList"
import './Share.css'
import { Modal, Button } from "react-bootstrap";
import { setToast } from '../../actions/toast'
import { useDispatch } from 'react-redux'

function Share({ setShowShare, folderName, folderId, isSession, sessionId, sharedUserID, setshowMember}) {
    var shareList = {};
    const members = useSelector(state => state.club.members)
    const userId = useSelector(state => state.user.id)
    const pId = useSelector(state => state.folder.pId)     
    if (sharedUserID) {
        sharedUserID = sharedUserID.split(',');
        sharedUserID = sharedUserID.map((i) => Number(i));
        sharedUserID.map((i) => {
            var findId = members.find(x => x.id === Number(i))
            if (Object.keys(findId).length > 0) {
                shareList[Number(i)] = findId.username;
            }
        });
    } else {
        sharedUserID = [];
    }
   
    const [shareWith, setshareWith] = useState(shareList)
    const [defaultShare, setdefaultShare] = useState(JSON.stringify(shareList))
    const [show, setShow] = useState(true);
    const handleClose = () => { setShow(false); setShowShare(false) };
    const [error, seterror] = useState(false)
    const [selected, setSelected] = useState(sharedUserID);
    const dispatch = useDispatch()
    const handleShareWith = (member) => {
        seterror(false)
        setshareWith(shareWith => {
            if (shareWith.hasOwnProperty(member.id)) {
                delete shareWith[member.id]
                setSelected(prevSelected => prevSelected.filter((s) => s !== member.id));
            } else {
                shareWith[member.id] = member.username
                setSelected(prevSelected => ([...prevSelected, member.id]));
            }
            return shareWith
        })
}
    const share = async () => {
        if (Object.keys(shareWith).length !== 0) {
            const shareResponse = isSession ? await shareSession({ sessionId, shareWith, sharedBy: userId, folderId }) : await shareNow({ shareWith, sharedBy: userId, folderId, pId })
            if (shareResponse.status === 200) {
                dispatch(isSession ? updateSharedUserID({ sessionId, selected: selected.join(',') }) : updateFolderSharedUserID({ folderId, selected: selected.join(',') }))
            }
            setShowShare(false)
            dispatch(setToast((isSession ? "Session" : "Folder") + " Shared Successfully!"))
        } else {
            seterror(true)
        }
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} className="folder-modal club-description-form">
                <Modal.Header closeButton>
                    <Modal.Title>Share Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sub-folder-name">
                        {members.filter(member => member.id !== userId && member.id !== setshowMember).map((member, index) => {
                            return (
                                <button className={selected.includes(member.id) ? "selected" : "default"} key={index} onClick={() => { handleShareWith(member) }} >{member.username}</button>
                            )
                        })}
                        {error && <p className="error">Select one user atleast</p>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={share}>
                        Share
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Share
