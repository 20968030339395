import { combineReducers } from 'redux';

import club from './club';
import user from './user';
import folder from './folder';
import isPublic from './isPublic';
import folderList from './folderList';
import sessionList from './sessionList';
import coachId from './coachId';
import toast from './toast';
import sortParam from './sortParam';
import filter from './filter';
import sidebar from './sidebar';
import toggleView from './toggleView';

export const reducers = combineReducers({ club, user, folder, isPublic, folderList, sessionList, coachId, toast, sortParam, filter, sidebar, toggleView });