import React, { useState } from 'react'
import Delete from '../Delete/Delete';
import { Image } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { FolderImg } from "../../assets/constants/img.js";

export default function FolderCard({ count, handleClick }) {
    const [folderDetails, setfolderDetails] = useState({});
    const sessionList = useSelector(state => state.sessionList);
    const folderlist = useSelector(state => state.folderList) || [];
    const [showFolderDelete, setshowFolderDelete] = useState(false);

    if (folderlist.length === 0 && sessionList.length === 0) {
        return <>
            <p className="loader-p">No Content</p>
        </>
    }

    return (
        <div className="folder-group">
            {folderlist.map((subFolder, index) => (
                <div className="folder-parent" key={index} onDoubleClick={() => handleClick(subFolder)} onTouchStart={() => handleClick(subFolder)}>
                    <div className={count <= 0 ? "" : "disable"} >
                        <Image src={FolderImg} className="folderImg" />
                    </div>
                    <div className="sub-folder-parent-parent">
                        <span className='file-counter'>
                            {subFolder.folder_counts > 0 ?  `${subFolder.folder_counts} Folders | ${subFolder.file_counts} Files` : `${subFolder.file_counts} Files`}
                        </span>
                        <p className="foldername" title={subFolder.name}>{subFolder.name}</p>
                    </div>
                </div>
            ))}
            {showFolderDelete && <Delete folder={true} setshowsessionDelete={setshowFolderDelete} folderDetails={folderDetails} />}
        </div>
    )
}
