import React, { useState, useEffect, useContext } from 'react'
import "./SessionFolder.css";
import Path from '../Path/Path';
import Share from '../Share/Share';
import Folder from '../Folder/Folder';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Sessions from '../Sessions/Sessions';
import { resetFilter } from '../../actions/filter'
import { resetParam } from '../../actions/sortParam'
import { setActiveSidebar } from '../../actions/sidebar';
import { SessionContext } from '../Context/SessionContext';

export default function SessionFolder({ clubCoach, reset }) {
    const [path, setpath] = useState([]);
    const dispatch = useDispatch();
    const [folderId, setfolderId] = useState("");
    const [folderName, setfolderName] = useState("");
    const [showShare, setshowShare] = useState(false);
    const pId = useSelector(state => state.folder.pId);
    const [sharedUserID, setsharedUserID] = useState("");
    const userName = useSelector(state => state.user?.name);
    const [folderLoading, setfolderLoading] = useState(true);
    const [sessionLoading, setsessionLoading] = useState({ loading: true, length: 0 });

    const {
        handleClick
    } = useContext(SessionContext)

    useEffect(() => {
        if (!clubCoach)
            dispatch(setActiveSidebar(1))
        window.scrollTo({ top: 0, left: 0 });
        return () => {
            dispatch(resetFilter())
            dispatch(resetParam())
        }
    }, [])

    const handlePath = (p, limit) => {
        if (limit === "clear") {
            setpath([])
        } else {
            let count = 0
            let newPath = path.filter((inner) => {
                if (count !== limit) {
                    count += 1
                    return inner
                }
            })
            setpath(newPath)
        }
    }

    return (
        <div className="folder-path-parent" onClick={() => { handleClick() }}>
            <Path reset={reset} handlePath={handlePath} path={path} title={userName || "Session"} />
            {/* 
                if new to club and admin show the club creation form
            */}

            {/* folders */}
            <Folder folderLoading={folderLoading} setfolderLoading={setfolderLoading} sessionLoading={sessionLoading} clubCoach={clubCoach} setfolderName={setfolderName} setshowShare={setshowShare} setfolderId={setfolderId} setpath={setpath} setsharedUserID={setsharedUserID} fromSessionFolder={true} />
            {(pId !== undefined) && <Sessions sessionLoading={sessionLoading} setsessionLoading={setsessionLoading} clubCoach={clubCoach} inClub={true} fromSessionFolder={true} />}
            {showShare && <Share folderName={folderName} setshowShare={setshowShare} folderId={folderId} sharedUserID={sharedUserID} />}
        </div >
    )
}