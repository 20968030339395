import { SET_PARENT  } from '../constants';
import * as api from '../apis/index.js';

export const setParent = (parentId) => async (dispatch) => {
  try {
    // const { data } = await api.saveSubClub(folderData);
    dispatch({ type: SET_PARENT, payload: parentId });
  } catch (error) {
    console.log(error);
  }
};
