import React, { useState, useEffect } from 'react';
import "./Dashboard.css";
import axios from "axios";
import Session from "../Cards/Session";
import { INFO } from '../../utils/constants';
import { resetFilter } from '../../actions/filter'
import { resetParam } from '../../actions/sortParam';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { resetToggle } from '../../actions/toggleView';
import { setActiveSidebar } from '../../actions/sidebar';
import {SessionContext} from '../Context/SessionContext';
import SessionFolder from "../SessionFolder/SessionFolder";
import { sessionList, clearSessionList } from '../../actions/sessionList';

export default function Dashboard({ setinClub, setinShared, id, setinDashboard, inDashboard }) {
    const dispatch = useDispatch()
    const [page, setpage] = useState(1);
    const [show, setShow] = useState(0);
    const [count, setcount] = useState(1);
    const [limit, setlimit] = useState(12);
    const [offset, setoffset] = useState(0);
    const [loading, setloading] = useState(true);
    const filter = useSelector(state => state.filter);
    const [sessionData, setsessionData] = useState([]);
    const isPublic = useSelector(state => state.isPublic);
    const sessions = useSelector(state => state.sessionList);
    const toggleView = useSelector(state => state.toggleView);
    const clubId = useSelector((state) => state.club.details?.id);
    const sortParam = useSelector((state) => state.sortParam.session);
    const [showEdit, setShowEdit] = useState({ id: 0, status: false });
    const [showShare, setShowShare] = useState({ id: 0, status: false });
    const [showDropdown, setShowDropdown] = useState({ id: 0, status: false });

    useEffect(() => {
        dispatch(setActiveSidebar(1));
        dispatch(resetFilter());
        dispatch(resetToggle());
        dispatch(resetParam());
        getSessions();
        setinDashboard(true);
        setinClub(false);
        setinShared(false);

        return () => {
            dispatch(clearSessionList());
            dispatch(resetFilter());
            dispatch(resetToggle());
            dispatch(resetParam());
            setinDashboard(false);
        }
    }, [])

    useEffect(() => {
        // dispatch(resetToggle())
        dispatch(resetFilter());
        dispatch(resetParam());
        setShow(0);
    }, [isPublic])

    useEffect(() => {
        if (toggleView === false){
            getSessions();
        }
        setShow(0)
    }, [page, offset, isPublic, sortParam, filter, toggleView])

    const handlePageChange = async (event, value) => {
        setpage(value);
        setoffset((value - 1) * 12);
    };

    function getSessions() {
        setloading(true)
        axios.post(process.env.REACT_APP_API_URL + '/session/sessionList', { userId: id, offset: offset, limit: limit, isPublic, sortParam, filter, clubId })
            .then(function (response) {
                if (response.data.responseCode === 200) {
                    const { sessionList: sessionlist, totalPages } = response.data.data
                    setsessionData(sessionlist)
                    dispatch(sessionList(sessionlist))
                    setcount(totalPages)
                } 
                setloading(false)
            }).catch(function (error) {
                setloading(false)
            });
    }

    const handleClick = () => {
        setShow(0);
        setShowEdit({ id: 0, status: false });
        setShowShare({ id: 0, status: false });
        setShowDropdown({ id: 0, status: false });
    }

    const renderPrivate = () => {
        if (loading === false && sessionData?.length > 0) {
            return (<>
                <div className="main-right-inner main-right-inner-parent-custom" onClick={() => handleClick()}>
                    <div className="main-right-inner-inner">
                        {sessions.map((data, index) => {
                            return (
                                <>
                                    {data.session_date && <Session
                                        key={index}
                                        data={data}
                                    />}
                                </>
                            )
                        })
                        }
                    </div>
                </div>
                {sessions.length > 0 && <Pagination
                    count={count}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                />}
            </>)
        } else if (sessions.length === 0 && loading === false) {
            return <p className="loader-p">No Content</p>
        } else {
            return <p className="loader-p">Loading...</p>
        }
    }

    const handleDropdownClick = (e, data) => {
        e.stopPropagation();
        if (data === INFO.MOVE_TO_FOLDER) {
            setShow(2);
        } else if (data === INFO.SHARE_SESSION) {
            setShowShare({ id: showDropdown.id, status: true })
            setShow(0)
        } else if (data === INFO.DOWNLOAD) {
            setShow(3)
        } else if (data === INFO.MAKE_A_COPY) {
            setShow(4)
        } else if (data === INFO.EDIT) {
            setShowEdit({ id: showDropdown.id, status: true })
            setShow(0)
        } else if (data === INFO . MOVE_TO_TRASH) {
            setShow(5)
        }
    }

    return (
        <>
            <SessionContext.Provider value={{
                getSessions,
                show, setShow,
                showEdit, setShowEdit,
                showShare, setShowShare,
                showDropdown, setShowDropdown,
                handleClick, handleDropdownClick,
            }}>
                {toggleView === false && renderPrivate()}
                {toggleView && <SessionFolder />}
            </SessionContext.Provider>
        </>
    )
}