import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const verifyToken = (token) => api.post('/user/verify', { token })
export const editDetails = (data) => api.post('/user/editDetails', data)
export const hasClub = (userId) => api.post('/clubs/hasClub', { userId })
export const isAdmin = (userId) => api.post('/clubs/isAdmin', { userId })
export const saveClub = (formData) => api.post('/clubs/saveClub', formData)
export const clubSessions = (data) => api.post('/clubs/sessions', data)
export const clubDetails = (data) => api.post('/clubs/clubDetails', data)
export const getMembers = (clubId, sortParam) => api.post('/clubs/getMembers', {clubId, sortParam})
export const saveSubClub = (subClubDetails) => api.post('/clubs/saveSubClub', subClubDetails)
export const subClubDetails = (subClubDetails) => api.post('/clubs/subClubDetails', subClubDetails)
export const getFoldersToChangeAccess = (subClubDetails) => api.post('/clubs/getFoldersToChangeAccess', subClubDetails)
export const shareNow = (data) => api.post('/clubs/share', data)
export const shareSession = (data) => api.post('/clubs/shareSession', data)
export const getShared = (data) => api.post('/clubs/getShared', data)
export const getSharedSessions = (data) => api.post('/clubs/getSharedSessions', data)
export const changeAccess = (data) => api.post('/clubs/changeAccess', data)
export const getClubSessions = (data) => api.post('/clubs/getClubSessions', data)
export const deleteSession = (sessionId) => api.delete(`/session/deleteSession/${sessionId}`)
export const deleteFolder = (folderId) => api.delete(`/clubs/deleteFolder/${folderId}`)
export const editSession = (data) => api.post('/clubs/editSession', data)
export const copySession = (data) => api.post('/clubs/copySession', data)

// get sessions and folders for normal user
export const normalUserFolders = (folderDetails) => api.post('/normal/userFolders', folderDetails)
export const normalUserGetFoldersToChangeAccess = (folderDetails) => api.post('/normal/getFoldersToChangeAccess', folderDetails)
export const saveFolder = (folderDetails) => api.post('/normal/saveFolder', folderDetails)
export const normalSessions = (data) => api.post('/normal/normalSessions', data)


// ownPublicFolders
export const ownPublicFolders = (subClubDetails) => api.post('/clubs/ownPublicFolders', subClubDetails)