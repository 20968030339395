import { UPDATE_FOLDER_LIST, DELETE_FOLDER, ADD_FOLDER, CLEAR_FOLDER, UPDATE_SHARED_USER } from '../constants';


export default (folderList = [], action) => {
  switch (action.type) {
    case UPDATE_FOLDER_LIST:
      return [...action.payload];
    case ADD_FOLDER:
      if (folderList.length === 0) {
        return [action.payload]
      } else {
        return [action.payload, ...folderList];
      }
    case DELETE_FOLDER:
      return folderList.filter(folder => folder.id !== action.payload)
    case CLEAR_FOLDER:
      return []
    case UPDATE_SHARED_USER:
      return folderList.map(folder => {
        if (action.payload.folderId === folder.id)
          folder.sharedUserID = action.payload.selected
        return folder
      })
    default:
      return folderList;
  }
};
