import React, { useState, useContext, useEffect } from 'react'
import { INFO } from '../../utils/constants';
import { setToast } from '../../actions/toast';
import { saveFolder, saveSubClub } from '../../apis';
import { useSelector, useDispatch } from 'react-redux';
import { changeAccess } from '../../actions/sessionList';
import { SessionContext } from '../Context/SessionContext';

export default function SubClubs(props) {
  const {
    getSessions,
    setfolders,
    sessionData,
    folderParent,
    setFolderList,
    setshowSubClub,
    isPublicOverride,
  } = props

  const dispatch = useDispatch();
  const [error, seterror] = useState(false);
  const [folderName, setfolderName] = useState("");
  const pId = useSelector((state) => state.folder.pId);
  const userId = useSelector((state) => state.user.id);
  const isPublic = useSelector(state => state.isPublic);
  const clubId = useSelector((state) => state.club.details);

  const {
    setShow,
    handleClick,
  } = useContext(SessionContext);


  const handleSave = async () => {
    try {
      if (folderName.trim() === "") {
        throw new Error('Folder name is required.');
      }
      if (!folderName.match(/^[a-z\d\-_\s]+$/i)) {
        throw new Error('Only alphanumeric allowed.');
      }

      let { data } = clubId.id == 1 ?
        await saveFolder({ folderName, parentId: folderParent || pId, userId, isPublic: isPublicOverride === undefined ? isPublic : isPublicOverride })
        : await saveSubClub({ folderName, clubId: clubId.id, parentId: folderParent ? folderParent : undefined, userId, isPublic: isPublicOverride === undefined ? isPublic : isPublicOverride })
      // dispatch(addFolder({ id: data.subClubId, name: folderName, club_id: clubId.id, parent_id: pId, user_id: userId }))
      setfolders && setfolders(folders => {
        return [{ id: data.subClubId, name: folderName, club_id: clubId.id, parentId: pId }].concat(...folders)
      })

      setFolderList && setFolderList(folders => {
        return [].concat(...folders, [{ id: data.subClubId, name: folderName, club_id: clubId.id, parentId: pId }])
      })

      if (data.responseCode === 200 && data.subClubId) {
        dispatch(changeAccess({ id: sessionData.id, isPublic: isPublicOverride, folderId: data.subClubId }))
        if (getSessions) {
          getSessions();
        }
        dispatch(setToast("Create Folder and Session Moved Successfully!"))
        handleClick();
      }

      setshowSubClub(false)
      setShow(0)
    } catch (error) {
      seterror(error.message)
    }
  }

  return (
    <div className='newDropdownLayout_Parent'>
      <div className='header'>
        <button className='back' onClick={() => setShow(1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M6.9 10.996h11.596a.75.75 0 0 1 0 1.5H6.9l4.377 4.377a.75.75 0 0 1-1.061 1.06l-4.95-4.95a1.75 1.75 0 0 1 0-2.474l4.95-4.95a.75.75 0 1 1 1.06 1.06L6.9 10.996z" fill="currentColor"></path></svg>
        </button>
        <h3>{INFO.MOVE_TO_FOLDER}</h3>
      </div>
      <div className='searcArea'>
        {clubId?.id ? <input type="text" placeholder="Folder name" className="folder-input search folderNew" maxLength={20} onChange={(e) => { setfolderName(e.target.value); seterror("") }} /> : <p>No Club Created</p>}
        {error && <p className="error">{error}</p>}
      </div>
      <div className='folderList'>

      </div>
      <div className='footerBtns'>
        <button className='btn move add' onClick={() => { handleSave() }}>Add to new folder</button>
      </div>
    </div>
  )
}
