import React, { useEffect, useState } from 'react'
import { saveClub } from '../../../apis'
import { useSelector } from 'react-redux';
import { Modal, Button, Image } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { updateClubDescription, setClubDetails } from '../../../actions/club';
import FileBase64 from 'react-file-base64';
import { ProfileImage, Logo } from "../../../assets/constants/img.js";


export default function Save({ setshowClubForm, setshowCreatePopUp}) {
    const userId = useSelector((state) => state.user.id);
    const club = useSelector((state) => state.club?.details);
    const [formData, setformData] = useState({ name: club?.id === 1 ? "" : club?.name, description: club?.id === 1 ? "" : club?.description, users: [], clubId: club?.id === 1 ? undefined : club?.id, adminId: userId })
    const [show, setShow] = useState(true);
    const dispatch = useDispatch()
    const handleClose = () => { setShow(false); setshowClubForm(false);};
    const [error, seterror] = useState(false)
    const isAdmin = useSelector((state) => state.user.isAdmin);
    console.log(isAdmin,"yeuiyeuiyeffvyeiufyeu")
    const [clubImage, setclubImage] = useState("");
    const [avtar, setavtar] = useState(`https://firebasestorage.googleapis.com/v0/b/touchtight-c809b.appspot.com/o/clubs%2F${club.id}?alt=media`);
    useEffect(() => {
        setavtar(() => {
            return avtar + `&randStr=${Math.random()}`
        })

    }, [])
    const saveFormData = () => {
        if (formData.name.trim() !== "") {
            saveClub({ ...formData, userId, clubImage, admin:isAdmin})
                .then(response => {
                    if (response.data.responseCode === 200) {
                        setshowClubForm(false)
                        console.log(formData, club?.id,"formDataformData");
                        if(club?.id && club?.id !== 1) {
                               dispatch(updateClubDescription(formData))}
                            else{ 
                                console.log('else')
                                dispatch(setClubDetails(response.data))}
                        setavtar((avtar) => avtar)
                    }
                }).catch(error => {
                    console.log(error)
                })
                setshowCreatePopUp(false);
        } else {
            // setshowClubForm(false)
            seterror(true)
        }
    }
    console.log(club?.id,formData,"============club?.name");
    return (
        <div>
            <Modal show={show} onHide={handleClose} className="folder-modal club-description-form">
                <Modal.Header closeButton>
                    <Modal.Title>Club Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"club-desc-row profile-box"}>
                        <Image
                            className={"edit-user-profile-image"}
                            src={avtar}
                            height="100"
                            width="100"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = ProfileImage;
                            }}
                        />
                        <div className="img-uploader" readOnly={!isAdmin}>
                            <button className={"upload-btn btn"} disabled={!isAdmin}>
                                <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1"><g><g transform="translate(-865.000000, -443.000000)" fill="#FFFFFF"><g transform="translate(690.000000, 308.000000)"><g transform="translate(160.000000, 126.000000)"><path d="M20.5,12.2 C20.5,11.7584 20.8584,11.4 21.3,11.4 C21.7416,11.4 22.1,11.7584 22.1,12.2 C22.1,12.6416 21.7416,13 21.3,13 C20.8584,13 20.5,12.6416 20.5,12.2 L20.5,12.2 Z M29.4,22.6 C29.4,23.0416 29.0416,23.4 28.6,23.4 L27.8,23.4 L27.8,20.2 C27.8,19.316 27.084,18.6 26.2,18.6 L19.8,18.6 C18.916,18.6 18.2,19.316 18.2,20.2 L18.2,23.4 L17.4,23.4 C16.9584,23.4 16.6,23.0416 16.6,22.6 L16.6,14.6352 C16.6,14.5288 16.6424,14.4272 16.7168,14.3528 L18.2,12.8696 L18.2,13.8 C18.2,14.684 18.916,15.4 19.8,15.4 L26.2,15.4 C27.084,15.4 27.8,14.684 27.8,13.8 L27.8,10.6 L28.6,10.6 C29.0416,10.6 29.4,10.9584 29.4,11.4 L29.4,22.6 Z M26.2,23.4 L19.8,23.4 L19.8,21 C19.8,20.5584 20.1584,20.2 20.6,20.2 L25.4,20.2 C25.8416,20.2 26.2,20.5584 26.2,21 L26.2,23.4 Z M19.8,11.2696 L20.4696,10.6 L26.2,10.6 L26.2,13 C26.2,13.4416 25.8416,13.8 25.4,13.8 L20.6,13.8 C20.1584,13.8 19.8,13.4416 19.8,13 L19.8,11.2696 Z M29.4,9 L20.1312,9 C19.9192,9 19.716,9.084 19.5656,9.2344 L15.2344,13.5656 C15.084,13.716 15,13.9192 15,14.1312 L15,23.4 C15,24.284 15.716,25 16.6,25 L29.4,25 C30.284,25 31,24.284 31,23.4 L31,10.6 C31,9.716 30.284,9 29.4,9 L29.4,9 Z"></path></g></g></g></g></svg>
                                Upload Image
                            </button>
                            {isAdmin && <FileBase64
                                multiple={false}
                                onDone={(files) => { setclubImage(files) }} 
                                />}
                        </div>
                    </div>

                    <div className="club-desc-row">
                        <label>Club Name: </label>
                        <input type="text" readOnly={!isAdmin} placeholder="Enter club name" value={formData.name} onChange={(e) => { setformData({ ...formData, name: e.target.value }); seterror(false) }} />
                    </div>
                    <div className="club-desc-row">
                        <label>Club Description: </label>
                        <textarea placeholder="Description" readOnly={!isAdmin} value={formData.description} onChange={(e) => { setformData({ ...formData, description: e.target.value }); seterror(false) }} ></textarea>
                    </div>
                    {error && <p className="error">Please fill all the fields</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {isAdmin && <Button variant="primary" onClick={saveFormData}>
                        Save
                    </Button>}
                </Modal.Footer>
            </Modal>

        </div>
    )
}
