import { COACH_ID, RESET_COACH_ID} from '../constants';

export const setCoachId = (userId) => async (dispatch) => {
  try {
    dispatch({ type: COACH_ID, payload: userId });
  } catch (error) {
    console.log(error);
  }
};


export const resetCoachId = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_COACH_ID, payload: {} });
  } catch (error) {
    console.log(error);
  }
};
