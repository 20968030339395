import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import { INFO } from '../../utils/constants.js';
import { MOVE_FOLDER } from "../../assets/constants/img.js";
import { SessionContext } from '../Context/SessionContext.js';
import {
    MdDelete,
    MdModeEdit,
    MdContentCopy,
    MdCloudDownload,
} from "react-icons/md";

const Dropdown = ({ inShared, data, key }) => {
    const user = useSelector(state => state.user);
    const userId = useSelector(state => state.user?.id);
    const isPublic = useSelector(state => state.isPublic);

    const {
        handleDropdownClick,
    } = useContext(SessionContext)


    return (
        <div className="footer-icon">
            <div className='header'>
                <h3>{data.session_name}</h3>
            </div>
            {(userId === data.user_id || user.isAdmin) && <div className="tooltip-parent footer-switch" onClick={(e) => handleDropdownClick(e, INFO.MOVE_TO_FOLDER)}>
                {!inShared &&
                    <>
                        <img className="move-folder" src={MOVE_FOLDER} key={key} />
                        <span className="custom-tooltip" >{INFO.MOVE_TO_FOLDER}</span>
                        <svg className='arrow' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path></svg>
                    </>
                } </div>}
            {!inShared && isPublic == 0 && <div className="tooltip-parent footer-switch" onClick={(e) => { handleDropdownClick(e, INFO.SHARE_SESSION) }}>
                <a href="javascript:void(0)" >
                    <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>
                </a>
                <span className="custom-tooltip">{INFO.SHARE_SESSION}</span>
            </div>
            }
            <div className="tooltip-parent footer-switch" onClick={(e) => handleDropdownClick(e, INFO.DOWNLOAD)}>
                <MdCloudDownload />
                <span className="custom-tooltip">{INFO.DOWNLOAD}</span>
                <div className='arrow'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path></svg>
                </div>
            </div>

            {(userId === data.user_id || user.isAdmin) &&
                <>
                    <div className="tooltip-parent footer-switch edit" onClick={(e) => handleDropdownClick(e, INFO.MAKE_A_COPY)} >
                        <MdContentCopy />
                        <span className="custom-tooltip">{INFO.MAKE_A_COPY}</span>
                    </div>
                    <div className="tooltip-parent footer-switch edit" onClick={(e) => handleDropdownClick(e, INFO.EDIT)} >
                        < MdModeEdit />
                        <span className="custom-tooltip">{INFO.EDIT}</span>
                    </div>
                    <div className="tooltip-parent footer-switch" onClick={(e) => { handleDropdownClick(e, INFO.MOVE_TO_TRASH) }}>
                        < MdDelete />
                        <span className="custom-tooltip">{INFO.MOVE_TO_TRASH}</span>
                    </div>
                </>}
        </div>
    )
}

export default Dropdown