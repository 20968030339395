import { SET_PARAM, RESET_PARAM } from "../constants";

export const setParam = (param) => async (dispatch) => {
  try {
    let payload = { session: "id desc", folder: "id desc" };
    switch (param) {
      case 1:
        payload.session = "session_name";
        payload.folder = "name";
        payload.label = "A -> Z";
        break;
      case 2:
        payload.session = "session_name desc";
        payload.folder = "name desc";
        payload.label = "Z -> A";

        break;
      case 3:
        payload.session = "id desc";
        payload.folder = "id desc";
        payload.label = "Created Date";
        break;
      case 4:
        payload.session = "modified_date desc";
        payload.folder = "id desc";
        payload.label = "Modified Date";

        break;
      case 6:
        payload.session = "username";
        payload.folder = "name";
        payload.label = "A -> Z";
        break;
      case 5:
        payload.session = "username desc";
        payload.folder = "name desc";
        payload.label = "Z -> A";

        break;
        case 7:
          payload.session = "id desc";
          payload.folder = "id desc";
          payload.label = "Modified Date";
  
          break;
    }
    dispatch({ type: SET_PARAM, payload });
  } catch (error) {
    console.log(error);
  }
};

export const resetParam = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_PARAM });
  } catch (error) {
    console.log(error);
  }
};
