export const USERID = 'USERID';
export const IS_ADMIN = 'IS_ADMIN';
export const CLUB_DETAIL = 'CLUB_DETAIL';
export const CLUB_MEMBERS = "CLUB_MEMBERS";
export const UPDATE_CLUB_DESCRIPTION = "UPDATE_CLUB_DESCRIPTION"
export const SET_PARENT = 'SET_PARENT';
export const UPDATE_FOLDER_LIST = "UPDATE_FOLDER_LIST";
export const ADD_FOLDER = "ADD_FOLDER";
export const IS_PUBLIC = "IS_PUBLIC";
export const IS_PRIVATE = "IS_PRIVATE";
export const CLEAR_FOLDER = "CLEAR_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const UPDATE_SESSION_LIST = "UPDATE_SESSION_LIST";
export const SESSION_LIST = "SESSION_LIST";
export const COACH_ID = "COACH_ID";
export const RESET_COACH_ID = "RESET_COACH_ID";
export const SET_TOAST = "SET_TOAST";
export const UNSET_TOAST = "UNSET_TOAST";
export const SET_PARAM = "SET_PARAM";
export const RESET_PARAM = "RESET_PARAM";
export const SET_FILTER = "SET_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const SET_TOGGLE = "SET_TOGGLE";
export const RESET_TOGGLE = "RESET_TOGGLE";
export const UPDATE_SHARED_USER = "UPDATE_SHARED_USER";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";
export const ACTIVE_SIDEBAR = "ACTIVE_SIDEBAR";
export const COPY_SESSION = "COPY_SESSION";

export const ageOptions = [
    { value: "", label: "None Selected" },
    { value: "Under 4s", label: "Under 4s" },
    { value: "Under 5s", label: "Under 5s" },
    { value: "Under 6s", label: "Under 6s" },
    { value: "Under 7s", label: "Under 7s" },
    { value: "Under 8s", label: "Under 8s" },
    { value: "Under 9s", label: "Under 9s" },
    { value: "Under 10s", label: "Under 10s" },
    { value: "Under 11s", label: "Under 11s" },
    { value: "Under 12s", label: "Under 12s" },
    { value: "Under 13s", label: "Under 13s" },
    { value: "Under 14s", label: "Under 14s" },
    { value: "Under 15s", label: "Under 15s" },
    { value: "Under 16s", label: "Under 16s" },
    { value: "Under 17s", label: "Under 17s" },
    { value: "Under 18s", label: "Under 18s" },
    { value: "Under 19s", label: "Under 19s" },
    { value: "Under 20s", label: "Under 20s" },
    { value: "Under 21s", label: "Under 21s" },
    { value: "Under 22s", label: "Under 22s" },
    { value: "Under 23s", label: "Under 23s" },
    { value: "Open Age", label: "Open Age" }
]

export const playerOptions = [
    { value: "", label: "None Selected" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
    { value: "34", label: "34" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" }
]

export const tactics = [
    { value: "", label: "None Selected" },
    { value: "Build Up Play", label: "Build Up Play" },
    { value: "Combinations", label: "Combinations" },
    { value: "Compactness", label: "Compactness" },
    { value: "Counter Attacking", label: "Counter Attacking" },
    { value: "Defensive Transition", label: "Defensive Transition" },
    { value: "Movement To Finish", label: "Movement To Finish" },
    { value: "Overloads", label: "Overloads" },
    { value: "Penetration", label: "Penetration" },
    { value: "Possession", label: "Possession" },
    { value: "Press and Cover", label: "Press and Cover" },
    { value: "Rotations", label: "Rotations" },
    { value: "Support Play", label: "Support Play" },
    { value: "Switching Play", label: "Switching Play" },
    { value: "Width and Depth", label: "Width and Depth" }
]

export const playPhase = [
    { value: "", label: "None Selected" },
    { value: "Begin the Attack", label: "Begin the Attack" },
    { value: "Possession Questions", label: "Possession Questions" },
    { value: "Finish the Attack", label: "Finish the Attack" },
    { value: "Press to Reagin", label: "Press to Reagin" },
    { value: "Organised Defending", label: "Organised Defending" },
    { value: "Defend the Goal", label: "Defend the Goal" }
]

export const practiceType = [
    { value: "", label: "None Selected" },
    { value: "Individual", label: "Individual" },
    { value: "Skill", label: "Skill" },
    { value: "Opposed", label: "Opposed" },
    { value: "Technical", label: "Technical" },
    { value: "Small Sided Games", label: "Small Sided Games" },
    { value: "Pattern", label: "Pattern" },
    { value: "Waves", label: "Waves" },
    { value: "Function", label: "Function" },
    { value: "Phase of Play", label: "Phase of Play" },
    { value: "Medium Sided Games", label: "Medium Sided Games" },
    { value: "Lead", label: "Lead" },
    { value: "Advanced Lead", label: "Advanced Lead" },
    { value: "11 v 11", label: "11 v 11" },
    { value: "Rondo", label: "Rondo" },
    { value: "Warm Up", label: "Warm Up" }
]


export const gameFormat = [
    { value: "", label: "None Selected" },
    { value: "1v1", label: "1 v 1" },
    { value: "2v2", label: "2 v 2" },
    { value: "3v3", label: "3 v 3" },
    { value: "4v4", label: "4 v 4" },
    { value: "5v5", label: "5 v 5" },
    { value: "6v6", label: "6 v 6" },
    { value: "7v7", label: "7 v 7" },
    { value: "8v8", label: "8 v 8" },
    { value: "9v9", label: "9 v 9" },
    { value: "10v10", label: "10 v 10" },
    { value: "11v11", label: "11 v 11" }
]