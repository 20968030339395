import { SET_TOAST, UNSET_TOAST  } from '../constants';


export default (toast = {flag:false, message: "Changes Updated!"}, action) => {
    switch (action.type) {
      case SET_TOAST:
        return {flag: true, message: action.payload};
      case UNSET_TOAST:
        return {flag: false, message: "Changes Updated!"};
      // case UPDATE:
      //   return posts.map((post) => (post._id === action.payload._id ? action.payload : post));
      // case DELETE:
      //   return posts.filter((post) => post._id !== action.payload);
      default:
        return toast;
    }
  };
  