import React from 'react'
import { useDispatch } from 'react-redux';
import { setParent } from '../../actions/folder';

export default function Path({ reset, path, handlePath, title }) {
    const dispatch = useDispatch();
    return (
        <div className="folder-path">
            {reset && <span onClick={() => { dispatch(setParent(undefined)); handlePath("p", "clear"); reset && reset() }}>Club Coaches &nbsp; &gt; &nbsp;</span>}
            <span onClick={() => { dispatch(setParent(undefined)); handlePath("p", "clear"); reset && reset() }}>{title}</span>
            {
                path.map((p, index) => <span key={index} onClick={() => { dispatch(setParent(index === 0 ? undefined : p.parent_id)); handlePath(p, index) }}><span className="folder-name"> &gt; &nbsp;{p.name}</span> </span>)
            }
        </div>
    )
}
