import React from 'react'
import { Modal, Button } from "react-bootstrap";

export default function LogOut({showLogOutPopUp, setshowLogOutPopUp}) {
    const logOut = () => {
        localStorage.clear();
        window.location.replace(process.env.REACT_APP_LEGACY_SITE_LOGOUT)
        document.cookie = "token_Item=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    return (
        <Modal show={showLogOutPopUp} onHide={()=>setshowLogOutPopUp(false)} className="folder-modal club-description-form delete-popup">
            <Modal.Header closeButton>
            <Modal.Title>Logout?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setshowLogOutPopUp(false)}>
                    No
                </Button>
                <Button variant="primary" onClick={logOut}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
