import { SET_FILTER, RESET_FILTER} from '../constants';


export default (filter = null, action) => {
    switch (action.type) {
      case SET_FILTER:
        return action.payload
      case RESET_FILTER:
        return null;
      default:
        return filter;
    }
  };
  