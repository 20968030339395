import { COACH_ID, RESET_COACH_ID } from '../constants';

export default (coach = {}, action) => {
  switch (action.type) {
    case COACH_ID:
      coach.id = action.payload.id
      coach.username = action.payload.username 
      return coach;
    case RESET_COACH_ID:
      return {}
    default:
      return coach;
  }
};
