import * as api from '../apis';
import {IS_PUBLIC, IS_PRIVATE} from '../constants'

export const isPublic = (value) => async (dispatch) => {
  try {
    // const { data } = await api.hasClub(userData);
    dispatch({ type: IS_PUBLIC, payload: 1  });
  } catch (error) {
    console.log(error);
  }
};

export const isPrivate = () => async (dispatch) => {
  try {
    // const { data } = await api.hasClub(userData);
    dispatch({ type: IS_PRIVATE, payload: 0  });
  } catch (error) {
    console.log(error);
  }
};