import React, {useState} from 'react'
import Save from '../Clubs/Form/Save';
import "./Create.css";
import LogOut from "../LogOut/LogOut.js";


export default function Create({showCreatePopUp, setshowCreatePopUp}) {
    const [showClubForm, setshowClubForm] = useState(false);
    const [showLogOutPopUp, setshowLogOutPopUp] = useState(false)
    const logout = () => {
        setshowLogOutPopUp(true);
        // setshowCreatePopUp(false);
    }
    const hide = () => {
          setshowClubForm(true);
        // setshowCreatePopUp(false);
        }  
    
  return (
   <>
    <div className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Club</h5>
          </div>
          <div className="modal-body">
            <p>Please create or join a club to get started!</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary " onClick={hide}>Create Club</button>
            <button type="button" className="btn btn-primary logout" onClick={logout}>Logout</button>
          </div>
        </div>
      </div>
    </div>

    {(showClubForm) && <Save setshowClubForm={setshowClubForm} setshowCreatePopUp={setshowCreatePopUp} />}
    {showLogOutPopUp && <LogOut showLogOutPopUp={showLogOutPopUp} setshowLogOutPopUp={setshowLogOutPopUp} />}      
   </>
  )
}

